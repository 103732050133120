import React, {CSSProperties, useEffect, useState} from "react";
import {Button, Stack, Typography, useMediaQuery} from "@mui/material";
import LargeArrowImage from "../assets/LargeArrowImage";
import theme from "./theme/theme";

function GrowUpCircle({onCLick}: { onCLick: any }) {

    const sm = useMediaQuery(theme.breakpoints.up('sm'));


    useEffect(() => {
        const interval = setInterval(() => {
            setRotation((prev) => prev + 0.75)
        }, 10);

        return () => clearInterval(interval);
    }, []);

    const [rotation, setRotation] = useState<number>(0)

    const circleStyle = (index: number) => {
        const degrees = 6.7
        return {
            fontFamily: "Cormorant",
            fontWeight: "600",
            fontSize: sm ? "1.7rem" : "0.9rem",
            height: sm ? "22rem" : "11rem",
            position: "absolute",
            transform: "rotate(" + (degrees * index + rotation) + "deg)",
        } as CSSProperties
    }

    function getGrowUp(order: number): React.ReactNode {
        const step = 9
        return <>
            <Typography component={"span"} style={circleStyle(1 + step * order)}>G</Typography>
            <Typography component={"span"} style={circleStyle(2 + step * order)}>r</Typography>
            <Typography component={"span"} style={circleStyle(3 + step * order)}>o</Typography>
            <Typography component={"span"} style={circleStyle(4 + step * order)}>w</Typography>
            <Typography component={"span"} style={circleStyle(5 + step * order)}>U</Typography>
            <Typography component={"span"} style={circleStyle(6 + step * order)}>p</Typography>
        </>
    }

    return (
        <>

            <Stack sx={{
                position: "relative",
                top: "0rem",
                height: {sm: "23rem", xs: "12rem"},
                width: {sm: "23rem", xs: "12rem"},
                borderRadius: "50%",
                border: "1px solid white",
                backgroundColor: "transparent",
                alignItems: "center",
                justifyContent: "center"
            }}>
                {getGrowUp(0)}
                {getGrowUp(1)}
                {getGrowUp(2)}
                {getGrowUp(3)}
                {getGrowUp(4)}
                {getGrowUp(5)}
                <Button variant={"contained"}
                        onClick={onCLick}
                        sx={{
                            minWidth: "auto",
                            position: "relative",
                            borderRadius: "50%",
                            padding: 0,
                            height: {sm: "8rem", xs: "4.15rem"},
                            width: {sm: "8rem", xs: "4.15rem"},
                            '&:hover': {
                                backgroundColor: '#8575DE',
                                color: '#fff',
                                boxShadow: 'none',
                            },
                        }}><LargeArrowImage size={sm ? 3.88 : 1.94}/></Button>
            </Stack>


        </>
    )
        ;
}

export default GrowUpCircle;
