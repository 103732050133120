import React from "react";
import {Chip, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import BulletImage from "../assets/BulletImage";
import GroupImage from "../assets/GroupImage";
import theme from "./theme/theme";


function ProgramItem({
                         time,
                         title,
                         desc,
                         presenter,
                         presenterTitle,
                         picUrl,
                         type,
                         picType,
                         isGroup = false,
                         onSite = false,
                         remote = false
                     }: {
    time: string, title: string, desc?: string,
    presenter: string, presenterTitle?: string,
    picUrl?: string, type: number, picType: number, isGroup?: boolean,
    onSite?: boolean,
    remote?: boolean
}) {

    const COLORS = ["#64CAD1", "#EB497A", "#FFBA99"]

    const SHAPES = ["50%", "6rem 6rem 2.2rem 2.2rem", "2.8rem", "2.2rem 2.2rem 6rem 6rem", "6rem 2.2rem 2.2rem 6rem "]
    const md = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <Grid container sx={{width: "100%"}} rowSpacing={1}>
                <Grid item md={2} xs={12}>
                    <Stack direction={"row"} sx={{alignItems: "center", height: "100%"}}>
                        <BulletImage color={COLORS[type]}/>
                        <Typography textAlign={"left"}
                                    sx={{marginLeft: "0.2rem", fontWeight: "600"}}>{time}
                            {onSite && !md && <Chip component={"span"} label={"On-site"} sx={{color: COLORS[type]}}/>}
                            {remote && !md && <Chip component={"span"} label={"Remote"} sx={{color: COLORS[type]}}/>}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item md={7} xs={12} sx={{alignSelf: "center"}}>
                    <Typography textAlign={"left"}
                                sx={{color: COLORS[type], ml: {md: 0, xs: "1.5rem"}}}>{title}
                        {onSite && md && <Chip component={"span"} label={"On-site"} sx={{color: COLORS[type]}}/>}
                        {remote && md && <Chip component={"span"} label={"Remote"} sx={{color: COLORS[type]}}/>}
                    </Typography>

                    <Typography textAlign={"left"}
                                sx={{
                                    ml: {md: 0, xs: "1.5rem"},
                                    marginTop: "0.5rem",
                                    display: desc ? "auto" : "none"
                                }}>{desc}</Typography>

                </Grid>
                <Grid item md={3} xs={12}>
                    <Stack direction={"row"} sx={{
                        alignItems: "center",
                        justifyContent: {md: "end", xs: "start"},
                        ml: {md: 0, xs: "1.5rem"},
                        marginTop: {md: 0, xs: "1rem"}
                    }}>
                        <Stack
                            sx={{
                                alignItems: {md: "end", xs: "start"},
                                marginRight: {md: "1rem", xs: 0},
                                marginLeft: {md: 0, xs: "1rem"}
                            }}
                            order={{md: 1, xs: 2}}>
                            <Typography textAlign={{md: "right", xs: "left"}}>{presenter}</Typography>
                            <Typography textAlign={{md: "right", xs: "left"}}>{presenterTitle}</Typography>
                        </Stack>
                        <Stack order={{md: 2, xs: 1}} sx={{
                            position: "relative",
                            height: "3.5rem",
                            width: "3.5rem",
                            borderRadius: SHAPES[picType],
                            backgroundImage: isGroup ? "" : "url(" + picUrl + ")",
                            backgroundColor: COLORS[type],
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            alignItems: "center",
                            justifyContent: "center",
                            ':before': {
                                height: "3.5rem",
                                width: "3.5rem",
                                backgroundColor: COLORS[type],
                                opacity: "0.3",
                                content: '""',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                borderRadius: SHAPES[picType],
                            }
                        }}> {isGroup ? <GroupImage width={1.5}/> : ""}</Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>

    )
        ;
}

export default ProgramItem;
