import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {toast} from 'react-toastify';

import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import CloseImage from "../assets/CloseImage";
import countries from "i18n-iso-countries"
import useFormsApi from "../api/useFormsApi";
import {fireStorage} from "./app/firebase-config";
import {isValidEmail} from "../util/formatUtil";
import ApplyInstruction from "./ApplyInstruction";
import theme from "./theme/theme";
import LinkedInTag from "react-linkedin-insight";

interface FormInfo {
    firstName: string;
    lastName: string;
    email: string;
    startup: string;
    landing: string;
    country: string;
    teamSize: string;
    comments: string;
}

const style = {
    boxShadow: "none",
    backgroundColor: "transparent",
    margin: {md: "2rem", xs: "1rem"},
    maxWidth: {md: "86rem !important", xs: "calc(100% - 2rem) !important"}
};

const FIELD_IDS = new Map([
    ["firstName", "1150868560"],
    ["lastName", "1228363317"],
    ["file", "2065441421"],
    ["email", "536521371"],
    ["startup", "1747145083"],
    ["landing", "1377559625"],
    ["country", "605771162"],
    ["teamSize", "121943809"],
    ["comments", "616003630"],

]);

const initialForm = {
    firstName: "",
    lastName: "",
    email: "",
    startup: "",
    landing: "",
    teamSize: "",
    country: "",
    comments: ""
} as FormInfo

const ApplyPopup = ({open, setOpen}: {
    open: boolean,
    setOpen: any,
}) => {

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const fileInput = React.createRef<HTMLInputElement>()
    const [form, setForm] = useState<FormInfo>(initialForm)
    const [file, setFile] = useState<File>()
    const [fileName, setFileName] = useState<string>("")
    const [applicationSent, setApplicationSent] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [countryNames, setCountryNames] = useState<{ label: string, value: string }[]>([])
    const {postFormData} = useFormsApi();
    const [errorFields, setErrorFields] = useState<string[]>([]);


    useEffect(() => {
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        const cc = Object.entries(countries.getNames("en", {select: "official"})).map(([key, value]) => {
            return {
                label: value,
                value: key
            }
        })
        setCountryNames(cc)
    }, []);

    const upload = () => {
        fileInput.current?.click()
    };

    const removeFile = () => {
        setFile(undefined)
        setFileName("")
    };

    const onUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length === 1) {

            const f = event.target.files[0]
            if (f.size / 1024 / 1024 > 20) {
                setErrorFields([...errorFields, "file"])
            }
            setFile(f)
            setFileName(f.name)
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setForm({...form, [e.target.id]: e.target.value})

    };

    const handleCountryChange = (event: SelectChangeEvent) => {
        setForm({...form, country: event.target.value})

    };

    const isFormValid = () => {
        if (!form) {
            return false;
        }
        const errors = [];
        const fieldNames = [];

        if (form.firstName === undefined || form.firstName?.trim().length === 0) {
            errors.push('firstName');
        }
        if (form.lastName === undefined || form.lastName?.trim().length === 0) {
            errors.push('lastName');
        }
        if (!isValidEmail(form?.email!)) {
            errors.push('email');
        }
        if (form.startup === undefined || form.startup?.trim().length === 0) {
            errors.push('startup');
        }

        /* removed for reminder sign-up
                if (form.teamSize === undefined || form.teamSize?.trim().length === 0) {
                    errors.push('teamSize');
                }
        */
        if (file && file.size / 1024 / 1024 > 20) {
            errors.push('file');
        }
        setErrorFields(errors);
        return errors.length <= 0;

    };

    const send = async () => {
        if (isFormValid()) {
            setLoading(true)
            const formData = new FormData();
            for (let [key, val] of Object.entries(form)) {
                let formKey = FIELD_IDS.get(key)
                if (formKey) {
                    formData.append("entry." + formKey, val)
                }
            }
            if (fileName && file) {
                const fileRef = ref(fireStorage, "pitchdecks/" + file.size + "_" + fileName)
                const snapshot = await uploadBytes(fileRef, file)
                let formKey = FIELD_IDS.get("file")
                const url = await getDownloadURL(snapshot.ref)
                formData.append("entry." + formKey, url)
            }


            postFormData(formData).then((response => {
                if (response.status != 0 && !response.ok) {
                    setLoading(false)
                    toast.error("Application sending failed. Please try again later or send it to hello@kofounder.ee!")
                } else {
                    setFile(undefined)
                    setFileName("")
                    setForm(initialForm)
                    setLoading(false)
                    setApplicationSent(true)
                    LinkedInTag.track("12260017");
                }
            })).catch((e) => {
                setLoading(false)
                toast.error("Application sending failed. Please try again later or send it to hello@kofounder.ee!")
            }).finally(() => {
                    setLoading(false)
                }
            )
        }

    };

    function renderLoading() {
        return <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.appBar + 1}}
            open={true}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    }

    function renderForm() {
        return <>
            <Typography textAlign={"center"} typography={"caption"} sx={{marginTop: {md: "5rem", xs: "3rem"}}}>Get a
                reminder for GrowUp Week 2024</Typography>
            <Grid container sx={{marginTop: "2rem", width: "100%"}}>
                <Grid item md={4} xs={12}>
                    <Stack direction={"row"}
                           sx={{
                               justifyContent: "start",
                               alignItems: "center",
                               width: "100%",
                           }}>
                        <Box sx={{width: {md: "auto", xs: "4rem"}}}>
                            < img src="/images/calendar.webp" width={md ? "100%" : "50%"} height={"auto"}/>
                        </Box>
                        <Stack>
                            <Typography typography={"caption"}
                                        sx={{
                                            display: {md: "block", xs: "none"},
                                            textAlign: "left",
                                            fontSize: "2rem",
                                            fontWeight: "600",
                                            lineHeight: "2rem",
                                            mb: "0.5rem"
                                        }}>
                                Date
                            </Typography>
                            <Typography typography={"body2"} sx={{textAlign: "left"}}>
                                1-5
                            </Typography>
                            <Typography typography={"body2"} sx={{textAlign: "left"}}>
                                April 2024
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack direction={"row"}
                           sx={{
                               justifyContent: {md: "center", xs: "start"},
                               alignItems: "center",
                               width: "100%",
                           }}>
                        <Box sx={{width: {md: "auto", xs: "4rem"}}}>
                            <img src="/images/globe.webp" width={md ? "100%" : "50%"} height={"auto"}/>
                        </Box>
                        <Stack>
                            <Typography typography={"caption"}
                                        sx={{
                                            display: {md: "block", xs: "none"},
                                            textAlign: "left",
                                            fontSize: "2rem",
                                            fontWeight: "600",
                                            lineHeight: "2rem",
                                            mb: "0.5rem"
                                        }}>
                                Location
                            </Typography>
                            <Typography typography={"body2"} sx={{textAlign: "left"}}>
                                Estonian Business School
                            </Typography>
                            <Typography typography={"body2"} sx={{textAlign: "left"}}>
                                A. Lauteri 3, Tallinn
                            </Typography>
                        </Stack>
                    </Stack>


                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack direction={"row"}
                           sx={{
                               justifyContent: {md: "end", xs: "start"},
                               alignItems: "center",
                               width: "100%",
                           }}>
                        <Box sx={{width: {md: "auto", xs: "4rem"}}}>
                            <img src="/images/coins.webp" width={md ? "100%" : "50%"} height={"auto"}/>
                        </Box>
                        <Stack>
                            <Typography typography={"caption"}
                                        sx={{
                                            display: {md: "block", xs: "none"},
                                            textAlign: "left",
                                            fontSize: "2rem",
                                            fontWeight: "600",
                                            lineHeight: "2rem",
                                            mb: "0.5rem"
                                        }}>
                                Price per team
                            </Typography>
                            <Typography typography={"body2"} sx={{textAlign: "left"}}>
                                266€
                            </Typography>

                        </Stack>
                    </Stack>


                </Grid>
            </Grid>

            <Stack sx={{
                width: "100%",
                alignItems: "center",
                backgroundColor: "#F8F7FA",
                borderRadius: {xl: "10.19rem", lg: "7.19rem", xs: "3rem"},
                marginTop: "3rem",
                marginBottom: {xl: "1rem", lg: "2rem", xs: "3rem"},
            }}>
                <Grid container
                      rowSpacing={"2rem"}
                      columnSpacing={{xl: "6rem", lg: "4rem", xs: "2rem"}}
                      sx={{
                          paddingTop: {xl: "4rem", lg: "3rem", xs: "2rem"},
                          paddingLeft: {xl: "4rem", lg: "3rem", xs: "2rem"},
                          paddingRight: {xl: "4rem", lg: "3rem", xs: "2rem"},
                          paddingBottom: {xl: "3rem", lg: "2rem", xs: "1rem"}
                      }}>
                    <Grid item md={6} xs={12} order={{md: 0, xs: 0}}>
                        <Typography typography={"caption"}
                                    sx={{
                                        textAlign: {md: "left", xs: "center"},
                                        fontSize: {md: "2rem !important", xs: "1.5rem !important"},
                                        fontWeight: "600",
                                        lineHeight: {md: "2.19rem !important", xs: "1.6rem !important"},
                                    }}>
                            Personal info
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12} order={{md: 1, xs: 4}}>
                        <Typography typography={"caption"}
                                    sx={{
                                        textAlign: {md: "left", xs: "center"},
                                        fontSize: {md: "2rem !important", xs: "1.5rem !important"},
                                        fontWeight: "600",
                                        lineHeight: {md: "2.19rem !important", xs: "1.6rem !important"},
                                    }}>
                            Company info
                        </Typography>
                    </Grid>
                    {/*
                    <Grid item md={4} xs={12} order={{md: 2, xs: 11}}/>
*/}

                    <Grid item md={6} xs={12} order={{md: 3, xs: 1}}>
                        <TextField label={"First name"} value={form.firstName} id={"firstName"}
                                   error={errorFields.includes("firstName")}
                                   helperText={!errorFields.includes("firstName") ? "" : "First name is required!"}
                                   onChange={(e) => handleChange(e)}/>
                    </Grid>

                    <Grid item md={6} xs={12} order={{md: 4, xs: 5}}>
                        <TextField label={"Company name"} value={form.startup} id={"startup"}
                                   error={errorFields.includes("startup")}
                                   helperText={!errorFields.includes("startup") ? "" : "Company name is required!"}
                                   onChange={(e) => handleChange(e)}/>
                    </Grid>
                    {/* <Grid item md={4} xs={12} order={{md: 5, xs: 8}}>

                        <TextField label={"Participating team size"} value={form.teamSize} id={"teamSize"}
                                   error={errorFields.includes("teamSize")}
                                   helperText={!errorFields.includes("teamSize") ? "" : "Team size is required!"}
                                   onChange={(e) => handleChange(e)}/>
                    </Grid>*/}

                    <Grid item md={6} xs={12} order={{md: 6, xs: 2}}>
                        <TextField label={"Last name"} value={form.lastName} id={"lastName"}
                                   error={errorFields.includes("lastName")}
                                   helperText={!errorFields.includes("lastName") ? "" : "Last name is required!"}
                                   onChange={(e) => handleChange(e)}/>
                    </Grid>
                    <Grid item md={6} xs={12} order={{md: 7, xs: 6}}>

                        <TextField label={"Landing page"} value={form.landing} id={"landing"}
                                   onChange={(e) => handleChange(e)}/>
                    </Grid>

                    {/*  <Grid item md={4} xs={12} order={{md: 8, xs: 9}}>

                        <FormControl fullWidth>
                            <TextField label={"Optional pitch deck"} sx={{
                                caretColor: "transparent", "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                            }}
                                       error={errorFields.includes("file")}
                                       disabled={true}
                                       helperText={!errorFields.includes("file") ? "" : "Maximum size is 20MB!"}
                                       value={fileName} id={"pitchDeck"}
                                       onClick={() => upload()}
                            >

                            </TextField>
                            <Tooltip title={"Browse.."}>
                                <Box sx={{
                                    position: "absolute",
                                    top: "1rem",
                                    right: "1rem",
                                    cursor: "pointer"
                                }} onClick={() => upload()}>
                                    <UploadImage/>
                                </Box>
                            </Tooltip>
                            <Tooltip title={"Remove file"}>
                                <Box sx={{
                                    display: fileName ? "auto" : "none",
                                    position: "absolute",
                                    top: "1.3rem",
                                    right: "3rem",
                                    cursor: "pointer"
                                }} onClick={() => removeFile()}>
                                    <RemoveFileImage/>
                                </Box>
                            </Tooltip>
                        </FormControl>
                        <input
                            ref={fileInput}
                            type="file"
                            style={{display: 'none'}}
                            onChange={(event) => onUploadChange(event)}
                        />
                    </Grid>
*/}
                    <Grid item md={6} xs={12} order={{md: 9, xs: 3}}>

                        <TextField label={"Email"} value={form.email} id={"email"}
                                   error={errorFields.includes("email")}
                                   helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                                   onChange={(e) => handleChange(e)}/>

                    </Grid>


                    <Grid item md={6} xs={12} order={{md: 10, xs: 7}}>

                        <FormControl fullWidth>
                            <InputLabel id="country">Country of origin</InputLabel>
                            <Select value={form.country}
                                    onChange={handleCountryChange} label={"Country of origin"}>
                                {countryNames.map((ccc, value) => {
                                    return <MenuItem key={ccc.value} value={ccc.value}>
                                        {ccc.label}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>


                    {/*   <Grid item md={4} xs={12} order={{md: 11, xs: 10}}>

                        <TextField label={"Comments or special requests"} value={form.comments}
                                   id={"comments"}
                                   onChange={(e) => handleChange(e)}/>

                    </Grid>*/}

                </Grid>
                <Button variant={"contained"} sx={{marginBottom: "1rem"}} onClick={send}>Send
                    application</Button>

            </Stack>
        </>
    }

    function renderConfirmation() {
        return <>

            <Typography typography={"caption"} sx={{marginTop: "5rem"}}>Thank you!</Typography>
            <Typography typography={"body2"} sx={{marginTop: "0rem", textAlign: "center"}}>We received your application.
                What will happen
                next?</Typography>
            <Grid container sx={{marginTop: {md: "2rem", xs: "1rem"}, width: "100%"}}>
                <Grid item md={6} xs={12} sx={{padding: {md: "2.7rem", xs: "1rem"}}}>
                    <ApplyInstruction number={"1"} title={"Validation call"}
                                      description={"We will validate your application and ask for a quick video call. Within 10 working days, we will share the decision, and if you’re accepted, we kindly ask you to pay the participation fee."}
                                      when={"Within 10 working days after you apply"}
                                      time={"15 min call"}/>
                </Grid>
                <Grid item md={6} xs={12} sx={{padding: {md: "2.7rem", xs: "1rem"}}}>
                    <ApplyInstruction number={"2"} title={"5-day hands on accelerator"}
                                      description={"Evaluate which metric needs the most attention. Achieve your goals with the help of mentors and other teams in 5 days. Pitch your results to the jury and investors"}
                                      when={"1st to 5th of April 2024"}
                                      time={"Daily from 9 AM to 5 PM, plus evening events"}/>

                </Grid>
            </Grid>
            <Button variant={"contained"} sx={{marginBottom: "1rem", marginTop: {md: 0, xs: "1rem"}}}
                    onClick={() => setOpen(false)}>Close</Button>
        </>;
    }

    return (
        <Dialog open={open} scroll={"body"} PaperProps={{
            sx: style,
            elevation: 0
        }}>
            <Button sx={{
                position: "absolute",
                top: 0,
                right: "0rem",
                backgroundColor: "#F7FF99",
                borderRadius: "50%",
                width: "3.31rem",
                height: "3.31rem",
                minWidth: "3.31rem",
                minHeight: "3.31rem"
            }} onClick={() => setOpen(false)}>
                <CloseImage/>
            </Button>

            <Stack sx={{
                alignItems: 'center',
                borderRadius: {md: "11rem", xs: "4rem"},
                backgroundColor: "#FFFFFF",
                paddingLeft: {xl: "4rem", lg: "3rem", xs: "1.5rem"},
                paddingRight: {xl: "4rem", lg: "3rem", xs: "1.5rem"},
                width: {xl: 'calc(100% - 8rem)', lg: 'calc(100% - 6rem)', xs: 'calc(100% - 3rem)'},
            }}>
                <Stack sx={{
                    width: "100%",
                    alignItems: 'center',

                }}>
                    {loading ? renderLoading() : (applicationSent ? renderConfirmation() : renderForm())}

                </Stack>
            </Stack>

        </Dialog>
    )
        ;
}

export default ApplyPopup;
