import React from 'react';

function GroupImage({width}: { width: number }) {
    return (
        <svg width={width + "rem"} height={width + "rem"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5947 10.9319C19.1971 10.9319 20.4969 9.63296 20.4969 8.03063C20.4969 6.42829 19.1971 5.12938 17.5947 5.12938"
                stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M18.9287 14.0848C19.4072 14.1178 19.883 14.1856 20.3514 14.291C21.0022 14.4184 21.785 14.6852 22.0637 15.2691C22.2415 15.6431 22.2415 16.0785 22.0637 16.4534C21.786 17.0373 21.0022 17.3032 20.3514 17.437"
                stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M6.28986 10.9319C4.68753 10.9319 3.3877 9.63296 3.3877 8.03063C3.3877 6.42829 4.68753 5.12938 6.28986 5.12938"
                stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4.95565 14.0848C4.47715 14.1178 4.0014 14.1856 3.53298 14.291C2.88215 14.4184 2.09931 14.6852 1.82156 15.2691C1.64281 15.6431 1.64281 16.0785 1.82156 16.4534C2.0984 17.0373 2.88215 17.3032 3.53298 17.437"
                stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.9377 14.7095C15.1845 14.7095 17.9583 15.2009 17.9583 17.1671C17.9583 19.1325 15.2028 19.6421 11.9377 19.6421C8.68991 19.6421 5.91699 19.1508 5.91699 17.1845C5.91699 15.2183 8.67249 14.7095 11.9377 14.7095Z"
                  stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.9378 11.9049C9.79651 11.9049 8.07959 10.188 8.07959 8.04575C8.07959 5.90442 9.79651 4.1875 11.9378 4.1875C14.0792 4.1875 15.7961 5.90442 15.7961 8.04575C15.7961 10.188 14.0792 11.9049 11.9378 11.9049Z"
                  stroke="#0A003E" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default GroupImage;
