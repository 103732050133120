import React from "react";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import GrowUpCircle from "../GrowUpCircle";
import SmallArrowImage from "../../assets/SmallArrowImage";
import CircledContainer from "../CircledContainer";

function Price({setApplyPopupOpen}: { setApplyPopupOpen: any }) {

    return (
        <Grid container sx={{maxWidth: "90rem", width: "100%"}}>
            <Grid item md={6} xs={12} sx={{position: "relative"}}>
                <CircledContainer coverProps={{
                    background: "conic-gradient(from 137.82deg at 50% 20.93%, #9E90EF -54.38deg, #8874E3 58.44deg, #9E90EF 305.62deg, #8874E3 418.44deg)",
                    mixBlendMode: "normal",
                    opacity: 1
                }}>


                    <Stack sx={{paddingLeft: "2rem", paddingRight: "2rem", alignItems: "center"}}>
                        <Typography sx={{textAlign: "center"}}>
                            Price per team
                        </Typography>
                        <Typography sx={{textAlign: "center"}}>
                            (up to 5 team-members)
                        </Typography>
                        <Typography
                            sx={{color: "#F7FF99", mb: {md: "1rem", xs: "0.5rem"}, mt: {md: "2rem", xs: "0.5rem"}}}
                            typography={"h1"}
                            component={"span"}>266€</Typography>

                        <Typography sx={{
                            textAlign: "center",
                            fontSize: {md: "0.9rem", xs: "0.7rem"},
                            paddingLeft: {md: 0, xs: "1rem"},
                            paddingRight: {md: 0, xs: "1rem"},
                            marginBottom: "1rem"
                        }}>
                            * Money back satisfaction guarantee
                        </Typography>

                        <Typography sx={{
                            textAlign: "center",
                            fontSize: {md: "0.9rem", xs: "0.7rem"},
                            mb: {md: "3rem", xs: "1rem"},
                            paddingLeft: {md: 0, xs: "1rem"},
                            paddingRight: {md: 0, xs: "1rem"},
                        }}>
                            The first batch of participants will be accepted by the 15th of January 2024 with a 30%
                            discount. The second batch of teams will be accepted by the 4th of March 2024.
                        </Typography>
                        <Button sx={{
                            width: {md: "auto", xs: "calc(100% - 4rem)"}
                        }} variant={"outlined"} onClick={() => setApplyPopupOpen(true)}>
                            <Stack direction={"row"} sx={{alignItems: "center"}}>
                                <Box sx={{marginRight: "1rem"}}>Get a reminder</Box>
                                <SmallArrowImage width={1.375}/>
                            </Stack>
                        </Button>
                    </Stack>
                </CircledContainer>
            </Grid>
            <Grid item md={6} xs={12}>
                <CircledContainer backgroundProps={{
                    backgroundColor: "#070140"
                }}>
                    <GrowUpCircle onCLick={() => setApplyPopupOpen(true)}/>
                </CircledContainer>

            </Grid>
        </Grid>
    )
        ;
}

export default Price;
