import React from 'react';

function BulletImage({color}: { color: string }) {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12L5.96534 11.7208C6.85965 11.68 7.70847 11.2176 8.34127 10.4266C8.97407 9.6356 9.34396 8.57457 9.37663 7.45668L9.59999 0L9.82335 7.45668C9.85603 8.57457 10.2259 9.6356 10.8587 10.4266C11.4915 11.2176 12.3403 11.68 13.2346 11.7208L19.2 12L13.2346 12.2792C12.3403 12.32 11.4915 12.7824 10.8587 13.5734C10.2259 14.3644 9.85603 15.4254 9.82335 16.5433L9.59999 24L9.37663 16.5433C9.34396 15.4254 8.97407 14.3644 8.34127 13.5734C7.70847 12.7824 6.85965 12.32 5.96534 12.2792L0 12Z"
                fill={color}/>
        </svg>


    );
}

export default BulletImage;
