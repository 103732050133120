import React from "react";
import {Box, Grid, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import theme from "./theme/theme";

function SpeakerProfile({
                            firstName,
                            lastName,
                            picUrl,
                            description,
                            type,
                            linkedin = "",
                        }: { firstName: string, lastName: string, picUrl: string, description: string, type: number, linkedin?: string }
) {

    const SHAPES = ["50%", "6rem 6rem 2.2rem 2.2rem", "2.8rem", "2.2rem 2.2rem 6rem 6rem", "6rem 2.2rem 2.2rem 6rem "]
    const md = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Stack sx={{alignItems: "center"}}>
            <Box sx={{
                position: "relative",
                height: {md: "12.5rem", xs: "10.6rem"},
                width: {md: "12.5rem", xs: "10.6rem"},
                borderRadius: SHAPES[type],
                backgroundImage: "url(" + picUrl + ")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                ':before': {
                    height: {md: "12.5rem", xs: "10.6rem"},
                    width: {md: "12.5rem", xs: "10.6rem"},
                    backgroundColor: "#050137",
                    opacity: "0.3",
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "0",
                    borderRadius: SHAPES[type],
                }
            }}>
                <Link href={linkedin} target={"_blank"}
                      sx={{
                          position: "relative",
                          left: "calc(100% - 31px)",
                          top: "calc(100% - 31px)",
                          visibility: linkedin ? "visible" : "hidden",
                          display: {md: "none", xs: "block"}
                      }}>
                    <img src="/images/linkedin.webp"/>
                </Link>
            </Box>
            <Stack sx={{alignItems: "center"}}>

                <Grid container sx={{
                    mt: "2rem",
                    maxWidth: {md: "14.5rem", xs: "calc(100% - 4rem)"},
                    paddingLeft: {md: 0, xs: "2rem"},
                    paddingRight: {md: 0, xs: "2rem"}
                }}>
                    <Grid item md={11} xs={12}>
                        <Typography typography={"h2"} sx={{textAlign: {md: "left", xs: "center"}}}>
                            {md ? firstName : firstName + " " + lastName}
                        </Typography>
                        <Typography typography={"h2"} sx={{textAlign: "left", display: {md: "block", xs: "none"}}}>
                            {lastName}
                        </Typography>

                    </Grid>
                    <Grid item md={1} display={{md: "block", xs: "none"}}>
                        <Link href={linkedin} target={"_blank"}
                              sx={{
                                  paddingTop: "2rem",
                                  visibility: linkedin ? "visible" : "hidden",
                              }}>
                            <img src="/images/linkedin.webp"/>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: {md: "left", xs: "center"}, mt: {md: "1rem", xs: 0}}}>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Stack>

    )
        ;
}

export default SpeakerProfile;
