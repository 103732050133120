import React, {useRef, useState} from "react";
import {Box, Button, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SmallArrowImage from "../assets/SmallArrowImage";
import theme from "./theme/theme";

const styles = {
    slide: {
        background: "transparent",
        height: "auto"
    },
};

const SWIPER_PAGES = 3


function LastYearSlideLarge({
                                picture1,
                                picture2,
                                picture3,
                                picture4,
                                testimonial1,
                                testimonial2,
                                author1,
                                authorPosition1,
                                author2,
                                authorPosition2
                            }: {
    picture1: string,
    picture2: string,
    picture3: string,
    picture4: string,
    testimonial1?: string,
    testimonial2?: string,
    author1?: string,
    authorPosition1?: string
    author2?: string,
    authorPosition2?: string
}) {

    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    return (<Stack sx={{alignItems: "center"}}>
            <Grid container sx={{maxWidth: xl ? "101rem" : "80rem"}} columnSpacing={3}>
                <Grid item xs={6}>
                    <Stack sx={{display: "flex", justifyContent: "end", alignItems: "end", width: "100%"}}>
                        <Stack direction={"row"}
                               sx={{justifyContent: "start", alignItems: "end", width: "100%"}}>
                            <Box sx={{
                                backgroundImage: "url(/images/" + picture1 + ")",
                                height: {xl: "22.5rem", md: "16.9rem"},
                                width: {xl: "22.5rem", md: "16.9rem"},
                                borderRadius: {
                                    xl: "11.19rem 11.19rem 4.14rem 4.14rem",
                                    md: "8.4rem 8.4rem 3.1rem 3.1rem"
                                },
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                position: "relative",
                                marginRight: {xl: "3rem", md: "1.5rem"}
                            }}/>
                            <Box sx={{
                                backgroundImage: "url(/images/" + picture2 + ")",
                                height: {xl: "39.4rem", md: "29.6rem"},
                                width: {xl: "22.5rem", md: "16.9rem"},
                                borderRadius: {xl: "9rem", md: "6.75rem"},
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",

                            }}/>
                        </Stack>
                        {testimonial1 && <Stack sx={{
                            alignItems: "start",
                            paddingTop: {xl: "5rem", md: "4rem"},
                            paddingRight: {xl: "5rem", md: "2rem"},
                            maxWidth: {xl: "37rem", md: "calc(50vw - 4rem)"},
                        }}>
                            <Typography sx={{fontSize: {xl: "1.25rem", md: "1.1rem"}, textAlign: "left"}}>
                                {testimonial1}
                            </Typography>
                            <Stack direction={"row"}
                                   sx={{
                                       justifyContent: "start",
                                       alignItems: "start",
                                       width: "100%",
                                       marginTop: "2rem"
                                   }}>
                                <Box sx={{
                                    backgroundColor: "#F7FF99",
                                    width: "3.44rem",
                                    height: "3.44rem",
                                    borderRadius: "6rem 2.2rem 2.2rem 6rem",
                                    marginRight: "1rem"
                                }}/>
                                <Stack>
                                    <Typography sx={{textAlign: "left"}}>
                                        {author1}
                                    </Typography>
                                    <Typography sx={{textAlign: "left"}}>
                                        {authorPosition1}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        }
                    </Stack>


                </Grid>
                <Grid item xs={6}>
                    <Stack sx={{
                        alignItems: "start",
                        paddingTop: {xl: "3rem", md: "2rem"},
                        paddingLeft: {xl: "5rem", md: "2rem"},
                        marginBottom: "4rem",
                        maxWidth: {xl: "37rem", md: "calc(50vw - 4rem)"},

                    }}>
                        {testimonial2 && <>
                            <Typography sx={{fontSize: {xl: "1.25rem", md: "1.1rem"}, textAlign: "left"}}>
                                {testimonial2}
                            </Typography>
                            <Stack direction={"row"}
                                   sx={{
                                       justifyContent: "start",
                                       alignItems: "start",
                                       width: "100%",
                                       marginTop: "2rem"
                                   }}>
                                <Box sx={{
                                    backgroundColor: "#F7FF99",
                                    width: "3.44rem",
                                    height: "3.44rem",
                                    borderRadius: "2.2rem 2.2rem 6rem 6rem",
                                    marginRight: "1rem"
                                }}/>
                                <Stack>
                                    <Typography sx={{textAlign: "left"}}>
                                        {author2}
                                    </Typography>
                                    <Typography sx={{textAlign: "left"}}>
                                        {authorPosition2}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </>}
                    </Stack>

                    <Stack>
                        <Stack direction={"row"} sx={{
                            justifyContent: "end",
                            alignItems: "start",
                            marginTop: testimonial2 ? 0 : {xl: "15rem", md: "10rem"}
                        }}>
                            <Box sx={{
                                backgroundImage: "url(/images/" + picture3 + ")",
                                height: {xl: "39.4rem", md: "29.5rem"},
                                width: {xl: "22.5rem", md: "16.9rem"},
                                borderRadius: {xl: "11.46rem", md: "8.6rem"},
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}/>
                            <Box sx={{
                                backgroundImage: "url(/images/" + picture4 + ")",
                                height: {xl: "23.1rem", md: "17.3rem"},
                                width: {xl: "23.1rem", md: "17.3rem"},
                                borderRadius: "50%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                position: "relative",
                                marginLeft: {xl: "3rem", md: "1.5rem"}
                            }}/>
                        </Stack>

                    </Stack>

                </Grid>
            </Grid>
        </Stack>
    )
};

function LastYearSwiperLarge() {

    const xl = useMediaQuery(theme.breakpoints.up('xl'));


    const swiperRef = useRef<SwiperCore>();
    const [swiperIndex, setSwiperIndex] = useState(0);

    function slideChange(s: SwiperCore) {
        setSwiperIndex(s.activeIndex)
    }

    function slide(direction: number) {
        if (direction > 0) {
            swiperRef.current?.slideNext()
        } else {
            swiperRef.current?.slidePrev()
        }
    }

    return (
        <Box sx={{position: "relative", width: "100%", marginBottom: "1rem"}}>
            <Button disabled={swiperIndex <= 0} sx={{
                minWidth: "3.44rem",
                width: "3.44rem",
                transform: "rotateY(180deg)",
                position: "absolute",
                left: "1.5rem",
                top: {xl: "16rem", md: "13rem"},
                zIndex: 2,
            }}
                    variant={"outlined"}
                    onClick={() => slide(-1)}
            >
                <SmallArrowImage width={1.375}/>
            </Button>
            <Button disabled={swiperIndex >= SWIPER_PAGES - 1} sx={{
                minWidth: "3.44rem",
                width: "3.44rem",
                position: "absolute",
                top: {xl: "16rem", md: "13rem"},
                right: "1.5rem",
                zIndex: 2,
            }} variant={"outlined"}
                    onClick={() => slide(1)}
            >
                <SmallArrowImage width={1.375}/>
            </Button>
            <Swiper modules={[Navigation, Pagination]} className="mySwiper"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    onSlideChange={(s) => slideChange(s)}
                    style={{
                        width: "100%",
                        paddingBottom: "4rem",
                    }}
                    pagination={{
                        clickable: true,
                        horizontalClass: "largeLastYearSwiper-pagination"
                    }}
                    breakpoints={{
                        // when window width is >= 320px
                        1606: {
                            slidesPerView: 1
                        }
                    }}
            >
                <SwiperSlide
                    style={{
                        ...styles.slide,
                        width: xl ? "101rem" : "80rem",
                        minWidth: xl ? "101rem" : "80rem"
                    }}>
                    <LastYearSlideLarge picture1={"lastyear7.webp"}
                                        picture2={"lastyear8.webp"}
                                        picture3={"lastyear9.webp"}
                                        picture4={"lastyear6.webp"}
                                        testimonial1={"“I had a fantastic experience at the GrowUp hackathon and would highly recommend it to anyone looking to be inspired, learn new skills and connect with the vibrant community. It was truly inspiring to see what participants are able to accomplish when focused.”"}
                                        testimonial2={"“Our international co-founding team has been working together for over 4 years in 3 countries, and KoFounder’s 1-week GrowUp Hackathon succeeded to push us beyond our limits. I can assure you this was the most productive week and impactful boost for our global company, and we have attended numerous accelerators. Therefore, I definitely recommend you KoFounder’s team as your partner.”"}
                                        author1={"Olena Iosifova"}
                                        authorPosition1={"CEO, Ender Turing"}
                                        author2={"Milo Radulovic"}
                                        authorPosition2={"CEO, MARLEQ"}
                    />
                </SwiperSlide>
                <SwiperSlide
                    style={{
                        ...styles.slide,
                        width: xl ? "101rem" : "80rem",
                        minWidth: xl ? "101rem" : "80rem"
                    }}>
                    <LastYearSlideLarge picture1={"lastyear10.webp"}
                                        picture2={"lastyear11.webp"}
                                        picture3={"lastyear12.webp"}
                                        picture4={"lastyear13.webp"}
                                        testimonial1={""}
                                        testimonial2={""}
                    /></SwiperSlide>
                <SwiperSlide
                    style={{
                        ...styles.slide,
                        width: xl ? "101rem" : "80rem",
                        minWidth: xl ? "101rem" : "80rem"
                    }}>
                    <LastYearSlideLarge picture1={"lastyear14.webp"}
                                        picture2={"lastyear15.webp"}
                                        picture3={"lastyear16.webp"}
                                        picture4={"lastyear17.webp"}
                                        testimonial1={""}
                                        testimonial2={""}
                    /></SwiperSlide>

            </Swiper>
        </Box>
    )
        ;
}

export default LastYearSwiperLarge;
