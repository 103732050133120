import React from "react";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import theme from "../theme/theme";
import LastYearSwiperSmall from "../LastYearSwiperSmall";
import LastYearSwiperLarge from "../LastYearSwiperLarge";

function LastYear() {

    const md = useMediaQuery(theme.breakpoints.up('md'));


    return (<Stack sx={{alignItems: "center", width: "100%", overflow: "hidden"}}>
            <Box sx={{marginTop: {md: "5rem", xs: "3rem"}, marginBottom: {md: "5rem", xs: "3rem"}}}>
                <Typography typography={"h1"} sx={{textAlign: "center"}}>
                    GrowUp 2021
                </Typography>
            </Box>
            {md ? <LastYearSwiperLarge/> : <LastYearSwiperSmall/>}

        </Stack>
    )
        ;
}

export default LastYear;
