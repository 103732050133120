import {Navigate, Route, Routes} from "react-router";
import CookiePolicy from "./CookiePolicy";
import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import Home from "./Home";

function AppRoutes() {


    const routes = [<Route path="/" element={<Home/>}/>,
        <Route path="/privacy" element={<PrivacyPolicy/>}/>,
        <Route path="/cookies" element={<CookiePolicy/>}/>

    ];

    const fallbackRoutePath = '/';

    return (
        <Routes>
            {routes.map((route, i) => <React.Fragment key={i}>{route}</React.Fragment>)}
            <Route path="*" element={<Navigate replace to={fallbackRoutePath}/>}/>
        </Routes>
    );
}

export default AppRoutes;