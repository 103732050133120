import React from 'react';

function SmallArrowImage({width}: { width: number }) {
    const height = width / 2.2
    return (
        <svg width={width + "rem"} height={height + "rem"} viewBox="0 0 22 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M22 5L13 10L15 5L13 0L22 5Z" fill="#06003F"/>
            <path d="M0 4L14 5L0 6V4Z" fill="#06003F"/>
        </svg>
    );
}

export default SmallArrowImage;
