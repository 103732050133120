import React from "react";
import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import ProgramItem from "../ProgramItem";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const styles = {
    tab: {
        flexGrow: 1,
        padding: {md: "0.4rem 0rem 0.4rem 0rem", xs: "0"},
        minHeight: "auto",
        height: "auto",
        width: "100%",
        fontFamily: "Cormorant",
        textTransform: 'none',
        borderRadius: {md: '2.6rem', xs: '1.3125rem'},
        boxShadow: 'none',
        fontWeight: "600",
        fontSize: {md: "2.375rem", xs: "1.5rem"},
        color: '#FFFFFF',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        '&:hover': {
            backgroundColor: '#8575DE',
            color: '#fff',
            boxShadow: 'none',
        },
    }
}
const activeStyles = {
    tab: {
        ...styles.tab,
        backgroundColor: '#F7FF99',
        color: "#090038",
        '&:hover': {
            backgroundColor: '#F7FF99',
            color: '#090038',
        },
    }
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index} = props;

    return (
        <>
            {value === index && (
                <>{children}</>
            )}
        </>
    );
}

function Program() {

    const WIDTH = "100%"

    const [value, setValue] = React.useState(0);

    const handleChange = (newValue: number) => {
        console.log("handlechange!", newValue)
        setValue(newValue);
    };

    const tabs = ["Mon.", "Tue.", "Wed.", "Thu.", "Fri."]

    return (
        <Box sx={{
            maxWidth: "90rem",
            backgroundColor: "#070140",
            borderRadius: {md: "12.5rem", xs: "3.75rem"},
            marginTop: {md: "6rem", xs: "2rem"},
            width: "100%"
        }}>
            <Stack sx={{
                alignItems: "center",
                padding: {xl: "9rem 9rem 9rem 9rem", lg: "5rem 5rem 5rem 5rem", xs: "4rem 1rem 3rem 1rem"}
            }}>
                <Box sx={{marginBottom: {md: "5rem", xs: "2rem"}, width: "100%"}}>
                    <Box sx={{marginBottom: "3rem"}}>
                        <Typography typography={"h1"} sx={{textAlign: "center"}}>
                            Program 2023
                        </Typography>
                    </Box>
                    <Swiper
                        preventClicks={true}
                        spaceBetween={6}
                        preventClicksPropagation={true}
                        slidesPerView={3.5}
                        style={{
                            width: "100%",
                            alignItems: 'center',
                        }}
                        breakpoints={{
                            600: {
                                slidesPerView: 5,
                            }
                        }}
                    >
                        {tabs.map((t, i) => {
                            return <SwiperSlide style={{width: "auto"}}>
                                <Button onClick={() => handleChange(i)}
                                        sx={value === i ? activeStyles.tab : styles.tab}>{t}</Button></SwiperSlide>
                        })}

                    </Swiper>

                </Box>
                <TabPanel value={value} index={0} key={"monday"}>
                    <ProgramItem time={"17:30 - 18:00"} title={"Gathering and registration at EBS"}
                                 desc={""}
                                 onSite
                                 presenter={""} presenterTitle={""} isGroup={true} type={2}
                                 picType={1}
                    />
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"18:00 - 18:30"} title={"Opening words"}
                                 onSite
                                 presenter={"Aive Uus"} presenterTitle={"CEO @KoFounder"} picUrl={"/images/aive.webp"}
                                 type={0}
                                 picType={4}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"18:30 - 19:00"} title={"Key-note"}
                                 onSite
                                 presenter={"Timmu Tõke"} presenterTitle={"CEO @Ready Player Me"} type={0}
                                 picUrl={"/images/timmu.webp"}
                                 picType={2}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"19:00 - 20:00"} title={"Workshop in teams"}
                                 desc={"Setting your Accelerator goals"}
                                 onSite
                                 presenter={""} presenterTitle={""} isGroup={true} type={1} picType={1}
                    />
                    <Divider sx={{width: WIDTH}}/>
                    <ProgramItem time={"20:00 - 22:00"}
                                 onSite
                                 title={"Presenting the goals and opening celebration"}
                                 presenter={""} presenterTitle={""} isGroup={true} type={0} picType={0}
                    />


                </TabPanel>
                <TabPanel value={value} index={1} key={"tuesday"}>
                    <ProgramItem time={"9:00 - 10:00"} title={"Unlock your product’s growth potential with metrics"}
                                 remote
                                 presenter={"Martin Küngas"} picUrl={"/images/martin.webp"} type={0} picType={4}
                                 presenterTitle={"Product Manager at Klaus"}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"10:00 - 11:00"}
                                 remote
                                 title={"Measuring emotions - joy, anger, confusion"}
                                 presenter={"Helen Kokk"} presenterTitle={"Product Design Lead"}
                                 picUrl={"/images/helen.webp"} type={0} picType={2}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"11:00 - 13:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={3}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"13:00 - 14:00"} title={"Individual mentor meetings"}
                                 presenter={"Choose your mentor"} presenterTitle={""}
                                 remote
                                 isGroup={true} type={1} picType={2}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"14:00 - 17:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={1}/>
                </TabPanel>
                <TabPanel value={value} index={2} key={"wednesday"}>
                    <ProgramItem time={"9:00 - 10:00"} title={"Leverage feedback loops to crown your customer"}
                                 remote
                                 desc={"Planning customer journey based on customer's needs, not yours"}
                                 presenter={"Katrin Vilimaa-Otsing"} picUrl={"/images/otsing.webp"} type={0} picType={1}
                                 presenterTitle={"Digital marketing expert"}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"10:00 - 11:00"} title={"KoThinker discussion"}
                                 desc={"Can ChatGPT transform how we build products?"}
                                 remote
                                 presenter={"KoThinker"} presenterTitle={""}
                                 picUrl={"/images/kothinker.webp"} type={0} picType={0}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"11:00 - 13:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={3}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"13:00 - 14:00"} title={"Individual mentor meetings"}
                                 presenter={"Choose your mentor"} presenterTitle={""}
                                 remote
                                 isGroup={true} type={1} picType={2}/>
                    <Divider sx={{width: WIDTH}}/>
                    <ProgramItem time={"14:00 - 17:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={1}/>
                    <Divider sx={{width: WIDTH}}/>
                    <ProgramItem time={"19:00 - 21:00"}
                                 onSite
                                 title={"Voluntary dinner meeting for sharing and supporting each other"}
                                 presenter={""} isGroup={true} type={2} picType={4}/>
                </TabPanel>
                <TabPanel value={value} index={3} key={"thursday"}>
                    <ProgramItem time={"9:00 - 10:15"}
                                 remote
                                 title={"Practical case study: Vocal Image's growth in the US market"}
                                 desc={"GrowUp 2022 winners share their examples of customer engagement experiments"}
                                 presenter={"Nick Lahoika"} picUrl={"/images/nick.webp"} type={0} picType={4}
                                 presenterTitle={"CEO @Vocal Image"}/>
                    <Divider sx={{width: WIDTH}}/>
                    <ProgramItem time={"10:15 - 11:00"}
                                 remote
                                 title={"How to Motivate Your Customers to Take Action"}
                                 desc={"Gamification Strategies & Tools to Drive Customer Loyalty and Engagement"}
                                 presenter={"Elena Lape"} picUrl={"/images/elena.webp"} type={0} picType={4}
                                 presenterTitle={"CEO @Holopin"}/>
                    <Divider sx={{width: WIDTH}}/>


                    <ProgramItem time={"11:00 - 13:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={3}/>
                    <Divider sx={{width: WIDTH}}/>


                    <ProgramItem time={"13:00 - 14:00"} title={"Individual mentor meetings"}
                                 remote
                                 presenter={"Choose your mentor"} presenterTitle={""}
                                 isGroup={true} type={1} picType={2}/>
                    <Divider sx={{width: WIDTH}}/>
                    <ProgramItem time={"14:00 - 17:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={1}/>

                </TabPanel>
                <TabPanel value={value} index={4} key={"friday"}>
                    <ProgramItem time={"9:00 - 10:00"}
                                 remote
                                 title={"How to get close to people?"}
                                 desc={"How customer research can humanize your product"}
                                 presenter={"Keiu Telve"} picUrl={"/images/keiu.webp"} type={0} picType={4}
                                 presenterTitle={"Founder @CAAE"}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"10:00 - 10:30"} title={"Pitching training"}
                                 remote
                                 presenter={"Mayri Tiido"} presenterTitle={"Public Speaking Coach"}
                                 picUrl={"/images/mayri.webp"} type={0} picType={4}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"10:30 - 13:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={3}/>
                    <Divider sx={{width: WIDTH}}/>


                    <ProgramItem time={"13:00 - 14:00"} title={"Individual mentor meetings"}
                                 remote
                                 presenter={"Choose your mentor"} presenterTitle={""}
                                 isGroup={true} type={1} picType={2}/>
                    <Divider sx={{width: WIDTH}}/>


                    <ProgramItem time={"14:00 - 16:00"}
                                 title={"Working on the Accelerator goals with the team"}
                                 presenter={"Gather your team"} isGroup={true} type={2} picType={1}/>
                    <Divider sx={{width: WIDTH}}/>


                    <ProgramItem time={"16:00 - 17:00"} title={"Practice pitches at the event venue"}
                                 onSite
                                 presenter={"Mayri Tiido"} presenterTitle={"Public Speaking Coach"}
                                 picUrl={"/images/mayri.webp"} type={1} picType={0}/>
                    <Divider sx={{width: WIDTH}}/>

                    <ProgramItem time={"18:00 - 22:00"} title={"Final pitching, WINNERS and celebration"}
                                 onSite
                                 presenter={"All teams"} presenterTitle={""}
                                 isGroup={true} type={2} picType={2}/>
                </TabPanel>
            </Stack>
        </Box>

    )
        ;
}

export default Program;
