import React from "react";
import {Stack, Typography} from "@mui/material";

function NumberCircle({number, color = "#8575DE"}: { number: string, color?: string }) {


    return (
        <Stack sx={{
            maxWidth: {md: "2.63rem", xs: "1.75rem"},
            maxHeight: {md: "2.63rem", xs: "1.75rem"},
            minWidth: {md: "2.63rem", xs: "1.75rem"},
            minHeight: {md: "2.63rem", xs: "1.75rem"},
            width: {md: "2.63rem", xs: "1.75rem"},
            height: {md: "2.63rem", xs: "1.75rem"},
            backgroundColor: "#F7FF99",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "0.2rem",
            paddingBottom: "0.1rem",
        }}>
            <Typography sx={{
                color: color,
                fontSize: {md: "2rem !important", xs: "1.33rem !important"},
                fontFamily: "Cormorant !important",
                fontWeight: "700 !important",
                lineHeight: {md: "2.2rem !important", xs: "1.47rem !important"},
                letterSpacing: "2%",

            }}>{number}</Typography>
        </Stack>
    )
        ;
}

export default NumberCircle;

