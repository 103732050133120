import React from 'react';

function MenuImage() {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 9H23" stroke="#DFE590" strokeWidth="2" strokeLinecap="round"/>
            <path d="M1.5 17H23" stroke="#DFE590" strokeWidth="2" strokeLinecap="round"/>
            <path d="M23 1H1.5" stroke="#DFE590" strokeWidth="2" strokeLinecap="round"/>
        </svg>


    );
}

export default MenuImage;
