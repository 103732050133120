import React from "react";
import {Stack, Typography} from "@mui/material";
import NumberCircle from "./NumberCircle";

function ApplyInstructionDark({
                                  number,
                                  title,
                                  description,
                                  when,
                                  time
                              }: { number: string, title: string, description: string, when: string, time: string }) {


    return (
        <Stack direction={"row"} sx={{marginBottom: {md: "3rem", xs: "2rem"}}}>
            <NumberCircle number={number}/>
            <Stack sx={{paddingLeft: "1.5rem", paddingTop: {md: "0.25rem", xs: 0}}}>
                <Typography typography={"h4"} sx={{paddingBottom: "0.5rem"}}>{title}</Typography>
                <Typography>{description}</Typography>
                <Typography sx={{marginTop: {md: "2rem", xs: "1rem"}, color: "#F7FF99"}}>
                    {"When: " + when}
                </Typography>
                <Typography sx={{color: "#F7FF99"}}>
                    {"Time: " + time}
                </Typography>
            </Stack>
        </Stack>
    )
        ;
}

export default ApplyInstructionDark;
