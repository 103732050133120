import React from "react";
import {Stack, Typography} from "@mui/material";
import NumberCircle from "./NumberCircle";

function ApplyInstruction({
                              number,
                              title,
                              description,
                              when,
                              time
                          }: { number: string, title: string, description: string, when: string, time: string }) {


    return (
        <Stack direction={"row"} sx={{marginBottom: {md: "3rem", xs: 0}}}>
            <NumberCircle number={number} color={"#06003F"}/>
            <Stack sx={{paddingLeft: "1.5rem", paddingTop: "0.25rem"}}>
                <Typography typography={"h4"} sx={{paddingBottom: "0.5rem", color: "#0A003E"}}>{title}</Typography>
                <Typography sx={{color: "#0A003E"}}>{description}</Typography>
                <Typography sx={{marginTop: "2rem", color: "#0A003E"}}>
                    {"When: " + when}
                </Typography>
                <Typography sx={{color: "#0A003E"}}>
                    {"Time: " + time}
                </Typography>
            </Stack>
        </Stack>
    )
        ;
}

export default ApplyInstruction;
