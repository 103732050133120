import React from 'react';

function CloseImage({color = "#0A003E"}: { color?: string }) {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2271 0.514719L9.62742 7.11438L3.02776 0.514719C2.50921 -0.00382647 1.66069 -0.00382647 1.14214 0.514719C0.623597 1.03326 0.623597 1.88179 1.14214 2.40034L7.74181 9L1.14214 15.5997C0.623597 16.1182 0.623597 16.9667 1.14214 17.4853C1.66069 18.0038 2.50921 18.0038 3.02776 17.4853L9.62742 10.8856L16.2271 17.4853C16.7456 18.0038 17.5942 18.0038 18.1127 17.4853C18.6312 16.9667 18.6312 16.1182 18.1127 15.5997L11.513 9L18.1127 2.40034C18.6312 1.88179 18.6312 1.03326 18.1127 0.514719C17.5942 -0.00382647 16.7456 -0.00382647 16.2271 0.514719Z"
                fill={color}/>
        </svg>
    );
}

export default CloseImage;
