import React from 'react';

function LargeArrowImage({size}: { size: number }) {
    return (
        <svg width={size + "rem"} height={size + "rem"} viewBox="0 0 62 62" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M52.1092 9.91219L44.4289 36.7934L38.6686 23.3528L25.228 17.5925L52.1092 9.91219Z" fill="#06003F"
                  stroke="#06003F" strokeWidth="2.71541"/>
            <path d="M7.94717 50.2341L36.7485 25.273L11.7873 54.0743L7.94717 50.2341Z" fill="#06003F"/>
        </svg>

    );
}

export default LargeArrowImage;
