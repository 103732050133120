import React, {CSSProperties} from "react";
import {Box, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import ApplyInstructionDark from "../ApplyInstructionDark";
import GrowUpCircle from "../GrowUpCircle";
import CircledContainer from "../CircledContainer";
import theme from "../theme/theme";

function WhoShouldApply({setApplyPopupOpen}: { setApplyPopupOpen: any }) {

    const md = useMediaQuery(theme.breakpoints.up('md'));


    const listItemStyle = {marginBottom: md ? "2rem" : "0rem"} as CSSProperties;
    const listStyle = {
        textAlign: "left",
        listStyleImage: 'url("/images/bullet.webp")',
        lineHeight: "1rem",
        listStylePosition: "outside",
        color: "#FFFFFF",
        marginTop: md ? "1rem" : "0.5rem",
        marginBottom: md ? "1rem" : "0.5rem",
    } as CSSProperties;

    return (
        <Box>
            <Grid container sx={{maxWidth: "90rem"}}>
                <Grid item xs={12} md={6} sx={{position: "relative"}}>
                    <Grid container>
                        <Grid item xs={12} order={{md: 1, xs: 2}}>
                            <Stack sx={{
                                alignItems: "center",
                                position: "relative",
                                ':before': {
                                    borderRadius: "21.32rem 21.32rem 9rem 9rem",
                                    content: '""',
                                    display: "block",
                                    height: "100%",
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    background: "conic-gradient(from 137.82deg at 50% 20.93%, #9E90EF -54.38deg, #8874E3 58.44deg, #9E90EF 305.62deg, #8874E3 418.44deg)",
                                }
                            }}>

                                <Stack
                                    sx={{
                                        marginTop: {md: "14rem", sm: "12rem", xs: "9rem"},
                                        marginBottom: {md: "10rem", xs: "3rem"},
                                        position: "relative",
                                        paddingLeft: {md: "4rem", xs: "1.5rem"},
                                        paddingRight: {md: "4rem", xs: "1.5rem"},
                                    }}>
                                    <Typography typography={"h1"}
                                                sx={{
                                                    textAlign: {md: "left", xs: "center"},
                                                    mb: {md: "3rem", xs: "2rem"}
                                                }}><Typography
                                        sx={{color: "#F7FF99"}} typography={"h1"}
                                        component={"span"}>Who</Typography> should apply?
                                    </Typography>

                                    <Grid container spacing={0} sx={{width: "100%"}}>
                                        <Grid item md={6} xs={12}>
                                            <ul style={listStyle}>
                                                <li style={listItemStyle}>
                                                    <Typography component={"span"}
                                                                sx={{top: "-6px", position: "relative"}}>
                                                        Your product has existing users to test new approaches and
                                                        implement change
                                                    </Typography>
                                                </li>

                                            </ul>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <ul style={listStyle}>
                                                <li style={listItemStyle}>
                                                    <Typography component={"span"}
                                                                sx={{top: "-6px", position: "relative"}}>
                                                        Your ambition and focus is to execute growth
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                    <Typography sx={{
                                        paddingLeft: "2.5rem",
                                        color: "#F7FF99"
                                    }}>{"Whether your business is big or small, B2C or B2B, you have apps or web, achieve the results you’re looking for!"}</Typography>

                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} order={{md: 2, xs: 1}}>
                            <CircledContainer backgroundProps={{
                                backgroundColor: "#070140"
                            }}>
                                <GrowUpCircle onCLick={() => setApplyPopupOpen(true)}/>
                            </CircledContainer>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack sx={{
                        height: "100%",
                        position: "relative",
                        alignItems: "center",
                        ':before': {
                            borderRadius: "21.32rem 21.32rem 21.32rem 21.32rem",
                            content: '""',
                            display: "block",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            background: "conic-gradient(from 137.82deg at 50% 10.46%, #9D8FEF -54.38deg, #8874E3 58.44deg, #9D8FEF 305.62deg, #8874E3 418.44deg)",
                            mixBlendMode: "normal"
                        }
                    }}>

                        <Stack sx={{
                            marginTop: {md: "14rem", sm: "12rem", xs: "9rem"},
                            position: "relative",
                            paddingLeft: {md: "4rem", xs: "1.5rem"},
                            paddingRight: {md: "4rem", xs: "1.5rem"},
                            marginBottom: "4rem",
                        }}>
                            <Typography typography={"h1"}
                                        sx={{
                                            textAlign: {md: "left", xs: "center"},
                                            mb: {md: "3rem", xs: "2rem"}
                                        }}><Typography
                                sx={{color: "#F7FF99"}} typography={"h1"}
                                component={"span"}>How</Typography> does it work?
                            </Typography>

                            <Stack>
                                <ApplyInstructionDark number={"1"} title={"Fill in the application"}
                                                      description={"We will remind you about GrowUp Week 2024 a few months before the event so you can make the final decision."}
                                                      when={"Start now!"}
                                                      time={"Takes around 3 min to apply"}/>
                                <ApplyInstructionDark number={"2"} title={"Validation call"}
                                                      description={"We will validate your application and ask for a quick video call. Within 10 working days, we will share the decision, and if you’re accepted, we kindly ask you to pay the participation fee."}
                                                      when={"Within 10 working days after you apply"}
                                                      time={"15 min call"}/>
                                <ApplyInstructionDark number={"3"} title={"5-day hands on accelerator"}
                                                      description={"Evaluate which metric needs the most attention. Achieve your goals with the help of mentors and other teams in 5 days. Pitch your results to the jury and investors"}
                                                      when={"1st to 5th of April 2024"}
                                                      time={"Daily from 9 AM to 5 PM, plus evening events"}/>
                            </Stack>
                        </Stack>
                    </Stack>

                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default WhoShouldApply;
