import {createTheme, ThemeOptions} from '@mui/material/styles';
import {orange} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

let defaultTheme = createTheme()

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
const themeOptions = {
    status: {
        danger: orange[500],
    },
    palette: {
        primary: {
            main: '#8575DE',
        },
        secondary: {
            main: '#0A003E',
        },
        background: {
            paper: '#fff',
            default: '#F7F7F7',
        },
    },
    typography: {
        fontFamily: 'Poppins',
        h1: {
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '2.375rem',
                lineHeight: '2.6125rem',
            },
            fontFamily: "Cormorant",
            color: '#FFFFFF',
            fontSize: '4.9rem',
            fontWeight: '600',
            lineHeight: '5.5rem',
        },
        h2: {
            fontFamily: "Cormorant",
            color: '#FFFFFF',
            fontSize: '2.6rem',
            fontWeight: '600',
            lineHeight: '3rem',
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '1.5rem',
                lineHeight: '1.6em',
            },
        },
        h3: {
            fontSize: '0.85rem',
            fontWeight: '600',

        },
        h4: {
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '1.5rem',
                lineHeight: '2.1rem',
            },
            fontFamily: "Cormorant",
            color: '#FFFFFF',
            fontSize: '2.38rem',
            fontWeight: '700',
            lineHeight: '3.33rem',
        },
        h6: {
            color: '#FFFFFF',
            fontSize: '1.25rem',
            fontWeight: '400',
            lineHeight: '1.75rem',

        },
        body1: {
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: '400',
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '0.9rem',
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: '0.875rem',
            },
        },
        body2: {
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '0.875rem',
                lineHeight: '1.4rem',
            },
            fontFamily: "Poppins",
            color: '#152A40',
            fontSize: '1rem',
            lineHeight: '1.6rem',
        },
        caption: {
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '1.25rem',
                lineHeight: '1.3rem',
            },
            fontFamily: "Cormorant",
            color: '#152A40',
            fontSize: '4.25rem',
            lineHeight: '4.675rem',
            fontWeight: '600',

        },
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '3.63rem',
                    fontSize: '1rem',
                    color: "#091E42",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#b4b4b4',
                    fontSize: '1rem',
                    border: "0",

                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#000000',
                    fontSize: '1rem',
                    border: "0",

                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': { // - Set the Input border when parent is focused
                        border: "4px solid rgba(133, 117, 222, 0.2)",
                    },

                    borderRadius: "0.63rem",
                    borderWidth: "3px",
                    backgroundColor: "#FFFFFF",
                    '& fieldset': {
                        border: "0",
                        "& focused": {
                            border: "2px",
                            borderColor: "green"
                        },
                    },
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    [defaultTheme.breakpoints.down("md")]: {
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                    },
                    color: "red",
                    fontSize: '1rem',
                    lineHeight: "1rem",
                    marginBottom: "-1.5rem",
                    textAlign: "left"
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused fieldset': { // - Set the Input border when parent is focused
                        border: "4px solid rgba(133, 117, 222, 0.2)",

                    },
                    borderRadius: "0.63rem",
                    borderWidth: "3px",
                    backgroundColor: "#FFFFFF",
                    '& fieldset': {
                        border: "0",
                        "& focused": {
                            border: "2px",
                            borderColor: "green"
                        },
                    },
                }
            }
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '0.8rem',
                    backgroundColor: "#2A2157",
                    borderRadius: "0.81rem",
                    marginLeft: "1rem",
                    maxHeight: "1.625rem",
                    paddingRight: "0.25rem",
                    paddingLeft: "0.25rem",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: "11.06rem",
                    minHeight: "3.438rem",
                    width: "11.06rem",
                    height: "3.438rem",
                    textTransform: 'none',
                    borderRadius: '1.4rem',
                    boxShadow: 'none',
                },
                contained: {
                    fontWeight: "600",
                    fontSize: "0.85rem",
                    color: '#06003F',
                    backgroundColor: '#F7FF99',
                    '&:hover': {
                        backgroundColor: '#8575DE',
                        color: '#fff',
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    fontWeight: "600",
                    fontSize: "0.85rem",
                    color: '#06003F',
                    backgroundColor: '#F7FF99',

                    '&:hover': {
                        backgroundColor: '#F7FF99',
                        border: '2px solid #06003F',
                        boxShadow: 'none',
                    },
                    '&:disabled': {
                        backgroundColor: '#b9b9b9',
                        boxShadow: 'none',
                    },
                },
                text: {
                    minWidth: "auto",
                    width: "auto",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                    color: '#FFFFFF',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: '#8575DE',
                        color: '#fff',
                        boxShadow: 'none',
                    },
                }
            },
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    right: "auto",
                    alignItems: "flex-end"
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#F7FF99",

                },
            },
        },

        MuiDivider: {
            styleOverrides: {
                root: {
                    height: "0.01rem",
                    opacity: "0.2",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "1.5rem",
                    marginTop: "1.5rem",
                }
            }
        },

    },
};
const theme = createTheme(themeOptions as ThemeOptions);

export default theme;
