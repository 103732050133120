import React, {useRef, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SmallArrowImage from "../assets/SmallArrowImage";

const styles = {
    slide: {
        background: "transparent",
        height: "auto"
    },
};

const SWIPER_PAGES = 8

function LastYearSwiperSmall() {


    const swiperRef = useRef<SwiperCore>();
    const [swiperIndex, setSwiperIndex] = useState(0);

    function slideChange(s: SwiperCore) {
        setSwiperIndex(s.activeIndex)
    }

    function slide(direction: number) {
        if (direction > 0) {
            swiperRef.current?.slideNext()
        } else {
            swiperRef.current?.slidePrev()
        }
    }

    return (
        <Box sx={{position: "relative", width: "100%", marginBottom: "1rem"}}>
            <Button disabled={swiperIndex <= 0} sx={{
                minWidth: "3.44rem",
                width: "3.44rem",
                transform: "rotateY(180deg)",
                position: "absolute",
                top: "calc(100% - 3.44rem + 0.5rem)",
                left: "1.5rem",
                zIndex: 3,
            }}
                    variant={"outlined"}
                    onClick={() => slide(-1)}
            >
                <SmallArrowImage width={1.375}/>
            </Button>
            <Button disabled={swiperIndex >= SWIPER_PAGES - 1} sx={{
                minWidth: "3.44rem",
                width: "3.44rem",
                position: "absolute",
                top: "calc(100% - 3.44rem + 0.5rem)",
                right: "1.5rem",
                zIndex: 3,
            }} variant={"outlined"}
                    onClick={() => slide(1)}
            >
                <SmallArrowImage width={1.375}/>
            </Button>
            <Swiper modules={[Pagination, Navigation]} className="smallLastYearSwiper"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={(s) => slideChange(s)}
                    slidesPerView={"auto"}
                    spaceBetween={0}
                    pagination={{
                        clickable: true,
                        horizontalClass: "smallLastYearSwiper-pagination"
                    }}
                    style={{
                        width: "100%",
                        alignItems: 'center',
                        paddingBottom: "4rem",
                    }}
            >
                <SwiperSlide style={{...styles.slide, maxWidth: "min(77vw, 77vw)"}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               maxWidth: "min(77vw, 77vw)",
                               justifyContent: "flex-end"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear7.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21.2vw, 21.2vh) min(21.2vw, 21.2vh) min(7.8vw, 7.8vh) min(7.8vw, 7.8vh)",
                            position: "relative",
                            marginRight: "1rem"
                        }}/>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear8.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(17vw, 17vh)",

                        }}/>


                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <Stack sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",

                        height: "100%"
                    }}>
                        <Typography sx={{textAlign: "left"}}>
                            “Our international co-founding team has been working together for over 4 years in 3
                            countries,
                            and KoFounder’s 1-week GrowUp Hackathon succeeded to push us beyond our limits. I can assure
                            you
                            this was the most productive week and impactful boost for our global company, and we
                            attended
                            numerous accelerators. Therefore, I definitely recommend you KoFounder’s team as your
                            partner.”
                        </Typography>
                        <Stack direction={"row"}
                               sx={{
                                   justifyContent: "start",
                                   alignItems: "start",
                                   width: "100%",
                                   marginTop: "2rem"
                               }}>
                            <Box sx={{
                                backgroundColor: "#F7FF99",
                                width: "2.875rem",
                                height: "2.875rem",
                                borderRadius: "2.2rem 2.2rem 6rem 6rem",
                                marginRight: "1rem"
                            }}/>
                            <Stack>
                                <Typography sx={{textAlign: "left"}}>
                                    Milo Radulovic
                                </Typography>
                                <Typography sx={{textAlign: "left"}}>
                                    CEO, MARLEQ
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </SwiperSlide>

                <SwiperSlide style={{...styles.slide,}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear6.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "50%",
                            position: "relative",
                            marginRight: "1rem"
                        }}/>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear9.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21vw, 21vh)",
                        }}/>

                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <Stack sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",

                        height: "100%"
                    }}>
                        <Typography sx={{textAlign: "left"}}>
                            “I had a fantastic experience at the GrowUp hackathon and would highly recommend it to
                            anyone looking to be inspired, learn new skills and connect with the vibrant community. It
                            was truly inspiring to see what participants are able to accomplish when focused.”
                        </Typography>
                        <Stack direction={"row"}
                               sx={{
                                   justifyContent: "start",
                                   alignItems: "start",
                                   width: "100%",
                                   marginTop: "2rem"
                               }}>
                            <Box sx={{
                                backgroundColor: "#F7FF99",
                                width: "2.875rem",
                                height: "2.875rem",
                                borderRadius: "6rem 2.2rem 2.2rem 6rem",
                                marginRight: "1rem"
                            }}/>
                            <Stack>
                                <Typography sx={{textAlign: "left"}}>
                                    Olena Iosifova
                                </Typography>
                                <Typography sx={{textAlign: "left"}}>
                                    CEO, Ender Turing
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={{...styles.slide}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear11.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(17vw, 17vh)",
                            marginRight: "1rem"

                        }}/>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear10.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21.2vw, 21.2vh) min(21.2vw, 21.2vh) min(7.8vw, 7.8vh) min(7.8vw, 7.8vh)",
                            position: "relative",
                        }}/>

                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={{...styles.slide,}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear13.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "50%",
                            position: "relative",
                            marginRight: "1rem"
                        }}/>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear12.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21vw, 21vh)",
                        }}/>

                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={{...styles.slide}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear14.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21.2vw, 21.2vh) min(21.2vw, 21.2vh) min(7.8vw, 7.8vh) min(7.8vw, 7.8vh)",
                            position: "relative",
                            marginRight: "1rem"
                        }}/>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear15.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(17vw, 17vh)",

                        }}/>
                    </Stack>
                </SwiperSlide>
                <SwiperSlide style={{...styles.slide,}}>
                    <Stack direction={"row"}
                           sx={{
                               position: "relative",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                        <Box sx={{
                            backgroundImage: "url(/images/lastyear16.webp)",
                            height: "min(74vw, 74vh)",
                            width: "min(43vw, 43vh)",
                            minHeight: "min(74vw, 74vh)",
                            minWidth: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "min(21vw, 21vh)",
                            marginRight: "1rem"

                        }}/>

                        <Box sx={{
                            backgroundImage: "url(/images/lastyear17.webp)",
                            minHeight: "min(43vw, 43vh)",
                            minWidth: "min(43vw, 43vh)",
                            height: "min(43vw, 43vh)",
                            width: "min(43vw, 43vh)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "50%",
                            position: "relative",
                        }}/>

                    </Stack>
                </SwiperSlide>
            </Swiper>
        </Box>
    )
        ;
}

export default LastYearSwiperSmall;
