import React from 'react';
import './App.css';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    Grid,
    MenuItem,
    Stack,
    Typography,
    useMediaQuery,
    useScrollTrigger
} from "@mui/material";
import "@fontsource/poppins";
import "@fontsource/cormorant";
import SmallArrowImage from "../../assets/SmallArrowImage";
import MenuImage from "../../assets/MenuImage";
import GrowUpLogo from "./GrowUpLogo";
import CloseImage from "../../assets/CloseImage";
import theme from "../theme/theme";


function HeaderBar({refs, setApplyPopupOpen}: { refs: any, setApplyPopupOpen: any }) {

    const md = useMediaQuery(theme.breakpoints.up('md'));


    const styles = {
        menu: {
            fontWeight: "500 !important",
            fontSize: "2.375rem !important",
            mb: "2.5rem"
        }
    }

    const [menuOpen, setMenuOpen] = React.useState(false);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    function goTo(anchor: string) {
        let props = md ? {behavior: "smooth", block: "start"} : {}
        refs.current[anchor].scrollIntoView(props)
        setMenuOpen(false)
    }

    return (
        <>
            <Dialog
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                sx={{
                    display: {xs: 'block', md: 'none'}, alignItems: "start", width: "100%"
                }}
                PaperProps={{
                    elevation: 0,
                    style: {
                        padding: "1rem",
                        alignItems: "center",
                        marginTop: 0,
                        margin: 0,
                        alignSelf: "start",
                        justifySelf: "start",
                        width: '100%',
                        minWidth: '100%',
                        backgroundColor: "#0A003E"
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <GrowUpLogo onClick={() => goTo('home')} width={145}/>
                    </Grid>
                    <Grid item xs={6}
                          sx={{alignItems: "top", justifyContent: "end", display: "flex", paddingRight: "1.5rem"}}>
                        <Box sx={{cursor: "pointer"}} onClick={() => setMenuOpen(false)}>
                            <CloseImage color={"#F7FF99"}/>
                        </Box>
                    </Grid>
                </Grid>
                <MenuItem sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "6rem"
                }} onClick={() => goTo('whyAttend')}>
                    <Typography sx={styles.menu}>Why attend?</Typography>
                </MenuItem>
                <MenuItem sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={() => goTo('speakers')}>
                    <Typography sx={styles.menu}>Mentors</Typography>
                </MenuItem>
                <MenuItem sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={() => goTo('program')}>
                    <Typography sx={styles.menu}>
                        Program
                    </Typography>
                </MenuItem>
                <Stack sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Button variant={"contained"} sx={{
                        display: {
                            marginTop: "3rem",
                            marginBottom: "1rem",
                            width: "calc(100% - 4rem)"
                        }
                    }} onClick={() => setApplyPopupOpen(true)}>
                        <Stack direction={"row"} sx={{alignItems: "center"}}>
                            <Box sx={{marginRight: "1rem"}}>Get a reminder</Box>
                            <SmallArrowImage width={1.375}/>
                        </Stack>
                    </Button>
                </Stack>
            </Dialog>
            <AppBar elevation={0} sx={{
                backgroundColor: trigger ? "#0A003E" : "transparent",
                transition: "background-color 500ms ease-in",
                paddingTop: {md: "2rem", xs: "1rem"},
                paddingBottom: {md: trigger ? "2rem" : "4rem", xs: trigger ? "1rem" : "2rem"},
                zIndex: 5
            }}>

                <Stack sx={{alignItems: "center", width: "100%"}}>

                    <Grid container sx={{
                        maxWidth: "90rem", minWidth: {md: "56.25rem", xs: "auto"},
                    }}>
                        <Grid item xs={6}>
                            <GrowUpLogo onClick={() => goTo('home')} width={md ? 165 : 145}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: {md: "flex", xs: "none"},
                                justifyContent: "center",
                                paddingRight: "1rem",
                                paddingLeft: "1rem"
                            }}>
                                <Button variant={"text"} onClick={() => goTo('whyAttend')}>Why attend?</Button>
                                <Button variant={"text"} onClick={() => goTo('speakers')}>Mentors</Button>
                                <Button variant={"text"} onClick={() => goTo('program')}
                                        sx={{marginRight: "1.5rem"}}>Program</Button>
                                <Button variant={"contained"} onClick={() => setApplyPopupOpen(true)}>
                                    <Stack direction={"row"} sx={{alignItems: "center"}}>
                                        <Box sx={{marginRight: "1rem"}}>Get a reminder</Box>
                                        <SmallArrowImage width={1.375}/>
                                    </Stack>
                                </Button>
                            </Box>
                            <Box sx={{
                                display: {
                                    xs: 'flex',
                                    md: 'none',
                                    cursor: "pointer",
                                    justifyContent: "end",
                                    marginRight: "1.5rem",
                                    marginTop: "0.5rem"
                                }
                            }}
                                 onClick={() => setMenuOpen(true)}>
                                <MenuImage/>
                            </Box>

                        </Grid>

                    </Grid>
                </Stack>
            </AppBar>
        </>
    )
        ;
}

export default HeaderBar;
