import React from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material";
import theme from "../theme/theme";
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/cormorant";
import "@fontsource/cormorant/500.css";
import "@fontsource/cormorant/600.css";
import "@fontsource/cormorant/700.css";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from "./AppRoutes";

function App() {

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <AppRoutes/>
        </ThemeProvider>
    );
}

export default App;

