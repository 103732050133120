import React from "react";
import {Box, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import theme from "../theme/theme";


function Partners() {
    const md = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box sx={{
            position: "relative",
            maxWidth: "90rem",
            width: "100%",
            backgroundColor: "#070140",
            borderRadius: {md: "12.5rem", xs: "3.75rem"},
            marginTop: "0rem",
        }}>
            <Stack sx={{alignItems: "center", padding: {xl: "4rem", lg: "4rem", xs: "3rem"}}}>
                <Typography>Special Thanks To All Our Partners</Typography>
                <Grid container columns={{md: 5, xs: 2}} sx={{marginTop: {md: "2rem", xs: "1rem"}}}
                      rowSpacing={{md: 3, xs: 2}}>
                    < Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/ebslogo.webp" width={md ? "150px" : "100px"}
                             height={md ? "77.72px" : "51.81px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/kofounderlogo.webp" width={md ? "150px" : "100px"}
                             height={md ? "42.98px" : "28.65px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/splash.webp" width={md ? "150px" : "100px"}
                             height={md ? "45.18px" : "30.12px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/vitamin.webp" width={md ? "150px" : "100px"} height={md ? "60px" : "40px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/liviko.webp" width={md ? "150px" : "100px"}
                             height={md ? "52.6px" : "35.1px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/ood.webp" width={"auto"}
                             height={md ? "100px" : "50.1px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/ingame.webp" width={"auto"}
                             height={md ? "55px" : "35.1px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/elamusgolf.webp" width={"auto"}
                             height={md ? "65px" : "40.1px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/vein.webp" width={"auto"}
                             height={md ? "80px" : "50.1px"}/>
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/skywheel.webp" width={"auto"}
                             height={md ? "80px" : "50.1px"}/>
                    </Grid>

                </Grid>
            </Stack>

        </Box>

    )
        ;
}

export default Partners;
