import React, {useRef} from "react";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import SpeakerProfile from "../SpeakerProfile";
import {Swiper, SwiperSlide} from "swiper/react";
import SmallArrowImage from "../../assets/SmallArrowImage";
import SwiperCore from "swiper";


function Speakers() {

    const swiperRef = useRef<SwiperCore>();

    function slide(direction: number) {
        if (direction > 0) {
            swiperRef.current?.slideNext()
        } else {
            swiperRef.current?.slidePrev()
        }
    }

    const profiles = [
        <SpeakerProfile firstName={"Mike"} lastName={"van der Valk"} picUrl={"/images/mike.webp"}
                        description={"Senior Product Manager at Pipedrive"}
                        type={0} linkedin={"https://www.linkedin.com/in/mikevandervalk"}/>,
        <SpeakerProfile firstName={"Kati"} lastName={"Kuustik"} picUrl={"/images/kati.webp"}
                        description={"Product Manager at Toggl"} linkedin={"https://www.linkedin.com/in/kuustikkati"}
                        type={1}/>,
        <SpeakerProfile firstName={"Keiu"} lastName={"Telve"} picUrl={"/images/keiu.webp"}
                        description={"Founder of Center for Applied Anthropology of Estonia"}
                        linkedin={"https://www.linkedin.com/in/keiu-telve-797a0a135"}
                        type={2}/>,
        <SpeakerProfile firstName={"Lev"} lastName={"Dolgatsjov"} picUrl={"/images/lev.webp"}
                        description={"President of EstBAN"} linkedin={"https://www.linkedin.com/in/lev-dolgatsjov"}
                        type={3}/>,
        <SpeakerProfile firstName={"Helen"} lastName={"Kokk"} picUrl={"/images/helen.webp"}
                        description={"Product Design Lead"}
                        linkedin={"https://www.linkedin.com/in/helen-kokk-product-design-lead"}
                        type={0}/>,
        <SpeakerProfile firstName={"Martin"} lastName={"Küngas"} picUrl={"/images/martin.webp"}
                        description={"Product Manager at Klaus"}
                        linkedin={"https://www.linkedin.com/in/martin-küngas-8b620686/"}
                        type={4}/>,
        <SpeakerProfile firstName={"Mart"} lastName={"Objartel"} picUrl={"/images/mart_objartel.webp"}
                        description={"Product Manager at Klaus"} linkedin={"https://www.linkedin.com/in/martobjartel/"}
                        type={2}/>,
        <SpeakerProfile firstName={"Nick"} lastName={"Lahoika"} picUrl={"/images/nick.webp"}
                        description={"Founder and CEO at Vocal Image"}
                        linkedin={"https://www.linkedin.com/in/nick-lahoika"}
                        type={1}/>,
        <SpeakerProfile firstName={"Urmo"} lastName={"Keskel"} picUrl={"/images/urmo.webp"}
                        description={"Touchpoint Product Manager at Textmagic"}
                        linkedin={"https://www.linkedin.com/in/urmo-keskel-7a73031b0"}
                        type={3}/>,
        <SpeakerProfile firstName={"Kitty"} lastName={"Mamers"} picUrl={"/images/kitty.webp"}
                        description={"Product Manager at Brompton Bicycle (ex-Starship)"}
                        linkedin={"https://www.linkedin.com/in/kitty-mamers/"}
                        type={0}/>,
        <SpeakerProfile firstName={"Karin"} lastName={"Maandi"} picUrl={"/images/maandi.webp"}
                        description={"Marketing & PR-Strategist at ALPA Kids"}
                        linkedin={"https://www.linkedin.com/in/karinmaandi/"}
                        type={1}/>,
        <SpeakerProfile firstName={"Mayri"} lastName={"Tiido"} picUrl={"/images/mayri.webp"}
                        description={"Public Speaking Coach"} linkedin={"https://www.linkedin.com/in/mayritiido"}
                        type={3}/>,
        <SpeakerProfile firstName={"Katrin"} lastName={"Vilimaa-Otsing"} picUrl={"/images/otsing.webp"}
                        description={"Digital marketing expert"}
                        linkedin={"https://www.linkedin.com/in/katrinvilimaa/"}
                        type={4}/>,
        <SpeakerProfile firstName={"Melissa"} lastName={"Sepp"} picUrl={"/images/melissa.webp"}
                        description={"Growth marketer in eCommerce and tech startups"}
                        linkedin={"https://www.linkedin.com/in/melissasepp/"}
                        type={3}/>,
        <SpeakerProfile firstName={"Helery"} lastName={"Pops"} picUrl={"/images/helery.webp"}
                        description={"Investor at Honey Badger Capital (ex-Pipedrive and ex-Ampler Bikes)"}
                        linkedin={"https://www.linkedin.com/in/helerypops/"}
                        type={2}/>,
        <SpeakerProfile firstName={"Gert"} lastName={"Lõhmus"} picUrl={"/images/gert.webp"}
                        description={"Chief Data Officer at Estateguru"}
                        linkedin={"https://www.linkedin.com/in/gertlohmus/"}
                        type={0}/>,
        <SpeakerProfile firstName={"Timmu"} lastName={"Tõke"} picUrl={"/images/timmu.webp"}
                        description={"CEO at Ready Player Me"}
                        linkedin={"https://www.linkedin.com/in/timmutoke/"}
                        type={1}/>,
        <SpeakerProfile firstName={"Aive"} lastName={"Uus"} picUrl={"/images/aive.webp"}
                        description={"CEO at KoFounder"}
                        linkedin={"https://www.linkedin.com/in/aive-uus/"}
                        type={4}/>,
        <div/>,
        <SpeakerProfile firstName={"Elena"} lastName={"Lape"} picUrl={"/images/elena.webp"}
                        description={"Founder & CEO at Holopin"}
                        linkedin={"https://www.linkedin.com/in/elenalape/"}
                        type={3}/>

    ];


    return (
        <Box sx={{maxWidth: "90rem", width: "100%"}}>
            <Box>
                <Box sx={{marginBottom: "3rem"}}>
                    <Typography typography={"h1"} sx={{textAlign: "center"}}>
                        Speakers and Mentors 2023
                    </Typography>
                </Box>
                <Grid container spacing={{xl: 10, md: 5}} sx={{
                    display: {md: "flex", xs: "none"},
                }}>
                    {profiles.map(p => {
                        return <Grid item md={4} xs={12}>
                            {p}
                        </Grid>
                    })}
                </Grid>
                <Stack sx={{
                    display: {md: "none", xs: "block"},
                    maxWidth: "85.44rem",
                    width: "100%",
                    alignItems: "center",
                    position: "relative"
                }}
                >
                    <Button sx={{
                        minWidth: "3.44rem",
                        width: "3.44rem",
                        transform: "rotateY(180deg)",
                        position: "absolute",
                        top: "calc(5.3rem - 3.44rem / 2)",
                        left: "1.5rem",
                        zIndex: 3,
                    }}
                            variant={"outlined"}
                            onClick={() => slide(-1)}
                    >
                        <SmallArrowImage width={1.375}/>
                    </Button>
                    <Button sx={{
                        minWidth: "3.44rem",
                        width: "3.44rem",
                        position: "absolute",
                        top: "calc(5.3rem - 3.44rem / 2)",
                        right: "1.5rem",
                        zIndex: 3,
                    }} variant={"outlined"}
                            onClick={() => slide(1)}
                    >
                        <SmallArrowImage width={1.375}/>
                    </Button>
                    <Swiper
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        className="speakers"
                        spaceBetween={16}
                        slidesPerView={1}
                        loop={true}
                        centeredSlides={true}
                        grabCursor={true}
                        style={{
                            width: "100%",
                        }}
                    >
                        {profiles.map(p => {
                            return <SwiperSlide>
                                {p}
                            </SwiperSlide>
                        })}
                    </Swiper>
                </Stack>
            </Box>
        </Box>
    )
        ;
}

export default Speakers;
