import React, {CSSProperties} from "react";
import {Box, Button, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import GrowUpCircle from "../GrowUpCircle";
import theme from "../theme/theme";
import SmallArrowImage from "../../assets/SmallArrowImage";


function WhyAttend({setApplyPopupOpen}: { setApplyPopupOpen: any }) {

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const TOP_OFFSET = "-11rem"
    const listStyle = {
        textAlign: "left",
        listStyleImage: 'url("/images/bullet.webp")',
        lineHeight: "1rem",
        listStylePosition: "outside",
        marginTop: md ? "1rem" : "0.5rem",
        marginBottom: md ? "1rem" : "0.5rem",
        color: "#FFFFFF"
    } as CSSProperties;

    return (
        <Stack sx={{alignItems: "center", width: "100%", overflow: "hidden", justifyContent: "center"}}>
            <Stack sx={{
                position: "absolute",
                alignItems: "start",
                left: {md: "calc(50% - 1400px)", xs: "calc(50vw - 50vw)"},
                backgroundColor: "#070140",
                clipPath: {md: "circle(450px at 950px 380px)", xs: "circle(58vw at center 20vw)"},
                width: {md: "1600px", xs: "100%"},
                height: {md: "900px", xs: "auto"},
                top: {md: "-110px", xs: "0"},
                zIndex: -3,
                overflow: "hidden",
                ':before': {
                    top: {md: "-0.5rem", xs: "0"},
                    content: '""',
                    width: {md: "1600px", xs: "100%"},
                    height: {md: "900px", xs: "calc(58vw + 280px)"},
                    position: "absolute",
                    backgroundColor: "#0A003E",
                    opacity: "0.6",
                }
            }}>
                <video autoPlay muted loop
                       style={{
                           transform: md ? "none" : "scale(1)",
                           position: "relative",
                           zIndex: -1,
                           height: md ? "900px" : "auto",
                           width: md ? "auto" : "140vw",
                           left: md ? 0 : "-40vw",
                       }
                       }>
                    <source
                        src="https://video.wixstatic.com/video/672c13_b56d7490cbd14a45892fb064e709158a/720p/mp4/file.mp4"
                        type="video/mp4"/>
                </video>
            </Stack>
            <Grid container sx={{maxWidth: "90rem", width: "100%"}}>
                <Grid item xs={12} md={6} position={"relative"} order={{xs: 2, md: 1}}>

                    <Box sx={{
                        padding: {md: "0rem 4rem 0 4rem", xs: "0rem 1.5rem 0 1.5rem"},
                        marginTop: {md: "825px", xs: "3rem"}
                    }}>
                        <Typography typography={"h1"} sx={{textAlign: "left", mb: {md: "3rem", xs: "1rem"}}}><Typography
                            sx={{color: "#F7FF99"}} typography={"h1"}
                            component={"span"}>Why</Typography> attend?</Typography>

                        <Grid container rowSpacing={"0.5rem"} columnSpacing={"1rem"}>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Solve your growth challenge, such as retention, acquisition,
                                            satisfaction, or other</Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Get feedback from experienced mentors in eight private sessions
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Evaluate improvements in your customer journey for the highest ROI
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Build better customer delight with six inspirational lectures
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Finish months of work in 5 days with a guided Google Design Sprint approach.
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ul style={listStyle}>
                                    <li>
                                        <Typography component={"span"}
                                                    sx={{top: "-6px", position: "relative"}}>
                                            Implement validated solutions you never thought of before
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} order={{xs: 1, md: 2}}>
                    <Stack sx={{
                        padding: {md: "0rem 5rem 0rem 5rem", xs: "0rem 2rem 0rem 2rem"},
                        position: "relative",
                        backgroundColor: "#0A003E",
                        height: {md: "calc(100% - " + TOP_OFFSET + ")", xs: "auto"},
                        top: {md: TOP_OFFSET, xs: 0},
                        marginTop: {md: 0, xs: "58vw"},
                        marginBottom: {md: TOP_OFFSET, xs: 0},
                        borderRadius: {md: "21.32rem 21.32rem 9rem 9rem", xs: "50vw 50vw 3.75rem 3.75rem"},
                        alignItems: "center"
                    }}>
                        <Stack sx={{paddingTop: {md: "25rem", xs: "5rem"}}}>
                            <Typography typography={"h1"} sx={{textAlign: "center"}}>GrowUp</Typography>
                            <Typography typography={"h1"}
                                        sx={{color: "#F7FF99", textAlign: "center"}}>Your Revenue</Typography>
                        </Stack>

                        <Typography sx={{mt: {md: "3rem", xs: "2rem"}}}>Increase revenue through product led
                            growth</Typography>
                        <Typography>Join a 5-day Sprint to solve your crucial challenge</Typography>

                        <Typography textAlign={"center"}
                                    sx={{color: "#F7FF99", mt: {md: "3rem", xs: "2rem", fontWeight: "600"}}}>This year’s
                            event is over, but you can already sign up to get a reminder about GrowUp Week
                            2024 in 1-5 of April</Typography>

                        <Button sx={{
                            display: {
                                md: "none",
                                xs: "block",
                                marginTop: "3rem",
                                marginBottom: "3rem",
                                width: "calc(100% - 4rem)"

                            }
                        }}
                                variant={"contained"}
                                onClick={() => setApplyPopupOpen(true)}>
                            <Stack direction={"row"}
                                   sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Box sx={{marginRight: "1rem"}}>Get a reminder</Box>
                                <SmallArrowImage width={1.375}/>
                            </Stack>
                        </Button>

                        <Stack sx={{
                            alignItems: "center",
                            marginTop: "12rem",
                            marginBottom: "8rem",
                            display: {md: "block", xs: "none"}
                        }}>
                            <GrowUpCircle onCLick={() => setApplyPopupOpen(true)}/>
                        </Stack>
                    </Stack>

                </Grid>
            </Grid>
        </Stack>
    )
        ;
}

export default WhyAttend;
