import React, {useEffect, useRef} from 'react';
import './App.css';
import {Box, Stack} from "@mui/material";
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/cormorant";
import "@fontsource/cormorant/500.css";
import "@fontsource/cormorant/600.css";
import "@fontsource/cormorant/700.css";
import HeaderBar from "./HeaderBar";
import WhoShouldApply from "../sections/WhoShouldApply";
import Speakers from "../sections/Speakers";
import WhyAttend from "../sections/WhyAttend";
import LastYear from "../sections/LastYear";
import Program from "../sections/Program";
import Venue from "../sections/Venue";
import Partners from "../sections/Partners";
import Price from "../sections/Price";
import ApplyPopup from "../ApplyPopup";
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import {NavLink} from "react-router-dom";
import LinkedInTag from 'react-linkedin-insight';

function Home() {


    const refs = useRef<any>({});
    const [applyOpen, setApplyOpen] = React.useState<boolean>(false)

    function setTrackingCookies() {
        ReactGA.initialize("G-YFWZ8VLBPP");
        ReactGA.send({hitType: "pageview", page: window.location.pathname});

        ReactPixel.init('1621463971543456', undefined, {
            autoConfig: true,
            debug: false
        });
        ReactPixel.pageView(); // For tracking page view
        LinkedInTag.init("5087401");
    }

    useEffect(() => {
        setTrackingCookies()
    }, []);

    return (
        <div className="App">

            <ApplyPopup open={applyOpen} setOpen={setApplyOpen}/>
            <CookieConsent
                location="bottom"
                style={{alignItems: "center", backgroundColor: "#FFFFFF", color: "#0A003E"}}
                onAccept={() => setTrackingCookies()}
                hideOnDecline={true}
                buttonText="Accept"
                buttonStyle={{backgroundColor: "#0A003E", color: "#FFFFFF"}}
            >
                This website uses cookies to enhance user experience. Cookies will be used for analytics and
                third-party tracking.<br/>More information: <NavLink to={"/privacy"} target={"_blank"}>Privacy
                Policy</NavLink>, <NavLink
                to={"/cookies"} target={"_blank"}>Cookie Policy</NavLink>
            </CookieConsent>
            <Stack sx={{alignItems: "center"}} ref={(el) => refs.current['home'] = el}>
                <HeaderBar refs={refs} setApplyPopupOpen={setApplyOpen}/>
                <Box id={"whyattend"} sx={{height: "0rem", scrollMarginTop: "-40rem"}}
                     ref={(el) => refs.current['whyAttend'] = el}/>
                <WhyAttend setApplyPopupOpen={setApplyOpen}/>
                <Box sx={{height: "1rem"}}/>
                <WhoShouldApply setApplyPopupOpen={setApplyOpen}/>
                <Box sx={{height: "6rem", scrollMarginTop: "5rem",}}
                     ref={(el) => refs.current['speakers'] = el}/>
                <Speakers/>
                <Box sx={{height: "1rem", scrollMarginTop: "3rem"}} ref={(el) => refs.current['program'] = el}/>
                <Program/>
                <Box sx={{height: "1rem"}}/>
                <Price setApplyPopupOpen={setApplyOpen}/>
                <Box sx={{height: "1rem"}}/>
                <LastYear/>
                <Box sx={{height: "1rem"}}/>
                <Venue/>
                <Box sx={{height: "1rem"}}/>
                <Partners/>
            </Stack>
        </div>
    );
}

export default Home;

