import React from 'react';
import './App.css';
import {Box} from "@mui/material";
import "@fontsource/poppins";
import "@fontsource/cormorant";


function GrowUpLogo({onClick, width}: { onClick: any, width: number }) {

    const height = width / 2.71

    return (
        <Box onClick={onClick}
             sx={{cursor: "pointer", marginLeft: {md: "4rem", xs: "1.5rem"}}}>
            <Box>
                <svg width={width} height={height} viewBox="0 0 230 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.6935 32.2527V16.7946H15.5918V20.7148H26.5506C26.3724 23.9668 23.8331 28.9562 16.7946 28.9562C11.137 28.9562 5.79122 24.8132 5.79122 16.4382C5.79122 8.06317 11.3152 4.00931 16.9727 4.00931C21.873 4.00931 25.5705 6.68219 26.996 11.2261L30.9608 9.53325C29.1343 3.83112 23.8331 0 16.9727 0C9.17686 0 1.33643 5.74668 1.33643 16.4382C1.33643 27.1297 8.73138 32.9209 16.6609 32.9209C21.9621 32.9209 25.3923 30.2926 26.7733 27.7979L27.1742 32.2527H30.6935Z"
                        fill="white"/>
                    <path
                        d="M47.9624 10.6024C47.7397 10.5579 47.1606 10.4688 46.5815 10.4688C44.3986 10.4688 41.7257 11.2706 40.1666 14.5672V10.8251H36.0681V32.2527H40.2557V21.5166C40.2557 16.7946 42.6167 14.9236 46.136 14.9236C46.7151 14.9236 47.3388 14.9681 47.9624 15.0572V10.6024Z"
                        fill="white"/>
                    <path
                        d="M60.494 29.1789C56.9302 29.1789 53.8118 26.506 53.8118 21.5166C53.8118 16.5718 56.9302 13.899 60.494 13.899C64.0578 13.899 67.1762 16.5718 67.1762 21.5166C67.1762 26.506 64.0578 29.1789 60.494 29.1789ZM60.494 10.1569C54.0791 10.1569 49.5352 15.0127 49.5352 21.5166C49.5352 28.0652 54.0791 32.9209 60.494 32.9209C66.9089 32.9209 71.4528 28.0652 71.4528 21.5166C71.4528 15.0127 66.9089 10.1569 60.494 10.1569Z"
                        fill="white"/>
                    <path
                        d="M87.5568 10.8251L81.9884 26.9515L77.1772 10.8251H72.5887L79.6273 32.2527H84.0376L89.6061 15.9482L95.3082 32.2527H99.6293L106.579 10.8251H102.169L97.4465 26.9515L91.878 10.8251H87.5568Z"
                        fill="white"/>
                    <path
                        d="M121.278 32.9209C127.693 32.9209 132.638 28.9116 132.638 21.4721V0.668218H128.272V21.3385C128.272 26.1496 125.733 28.8671 121.278 28.8671C116.868 28.8671 114.24 26.1496 114.24 21.3385V0.668218H109.919V21.4721C109.919 28.9116 114.863 32.9209 121.278 32.9209Z"
                        fill="white"/>
                    <path
                        d="M142.797 40.7168V29.3571C143.955 31.2726 146.45 32.8318 149.746 32.8318C156.027 32.8318 159.725 27.887 159.725 21.5166C159.725 15.2799 156.295 10.3351 149.88 10.3351C146.405 10.3351 143.866 12.0279 142.708 14.1662V10.8251H138.654V40.7168H142.797ZM155.448 21.5166C155.448 25.9269 152.998 29.0898 149.122 29.0898C145.291 29.0898 142.752 25.9269 142.752 21.5166C142.752 17.24 145.291 14.0326 149.122 14.0326C153.043 14.0326 155.448 17.24 155.448 21.5166Z"
                        fill="white"/>
                    <path
                        d="M32.0745 77.2748L23.9668 52.2835H19.3783L11.4488 77.0076L4.49934 52.2835H0L9.13233 83.8679H13.498L21.6503 58.2529L29.8917 83.8679H34.391L43.3897 52.2835H38.8903L32.0745 77.2748Z"
                        fill="white"/>
                    <path
                        d="M47.8779 70.949C48.0561 68.1871 50.1944 65.4696 53.7137 65.4696C57.5002 65.4696 59.5049 67.8752 59.594 70.949H47.8779ZM60.2177 76.5175C59.4158 78.9231 57.6784 80.8387 54.2037 80.8387C50.5953 80.8387 47.8333 78.1658 47.7442 74.3347H63.8706C63.9151 74.0674 63.9597 73.5328 63.9597 72.9982C63.9597 66.3161 60.2622 61.7722 53.6691 61.7722C48.2788 61.7722 43.4231 66.3606 43.4231 73.0873C43.4231 80.3486 48.457 84.5362 54.2037 84.5362C59.1931 84.5362 62.6232 81.5514 63.826 77.7649L60.2177 76.5175Z"
                        fill="white"/>
                    <path
                        d="M71.1316 70.949C71.3097 68.1871 73.448 65.4696 76.9673 65.4696C80.7539 65.4696 82.7586 67.8752 82.8477 70.949H71.1316ZM83.4713 76.5175C82.6695 78.9231 80.9321 80.8387 77.4574 80.8387C73.849 80.8387 71.087 78.1658 70.9979 74.3347H87.1243C87.1688 74.0674 87.2134 73.5328 87.2134 72.9982C87.2134 66.3161 83.5159 61.7722 76.9228 61.7722C71.5325 61.7722 66.6768 66.3606 66.6768 73.0873C66.6768 80.3486 71.7107 84.5362 77.4574 84.5362C82.4467 84.5362 85.8769 81.5514 87.0797 77.7649L83.4713 76.5175Z"
                        fill="white"/>
                    <path
                        d="M110.868 62.4404H105.077L95.8108 71.9291V51.6152H91.6678V83.8679H95.8108V77.4085L98.7955 74.4238L105.789 83.8679H111.046L101.736 71.4836L110.868 62.4404Z"
                        fill="white"/>
                    <path
                        d="M125.303 56.5526C125.284 56.3596 125.264 56.1665 125.264 55.9928C125.264 54.5643 126.037 53.4253 127.677 53.4253C129.26 53.4253 130.033 54.4871 130.033 55.626C130.033 56.5912 129.492 57.4792 128.315 58.2321L126.23 59.5834C124.106 60.9539 123.295 62.5176 123.257 64.5638H132.021V62.8071H125.593C125.766 62.035 126.403 61.5138 127.118 61.0505L129.589 59.4675C131.017 58.5602 131.944 57.2476 131.944 55.6067C131.944 53.5219 130.399 51.7652 127.716 51.7652C124.975 51.7652 123.392 53.6763 123.392 55.8191C123.392 55.9735 123.392 56.1472 123.411 56.321L125.303 56.5526Z"
                        fill="white"/>
                    <path
                        d="M141.64 52.0548H132.509V53.7921H139.555C139.555 53.7921 134.961 57.2862 134.401 64.5638H136.351C136.949 57.9425 140.482 54.7959 141.64 53.8693V52.0548Z"
                        fill="white"/>
                    <path d="M150.937 59.815V58.2321H145.705V59.815H150.937Z" fill="white"/>
                    <path
                        d="M159.248 58.7533C159.576 58.6375 159.962 58.5602 160.348 58.5602C161.583 58.5602 162.896 59.2552 162.896 60.896C162.896 62.1508 161.97 63.2125 160.251 63.2125C158.746 63.2125 157.549 62.1701 157.472 60.51L155.619 60.9346C155.734 62.9809 157.375 64.8727 160.271 64.8727C163.186 64.8727 164.807 62.9616 164.807 60.8574C164.807 58.29 162.78 57.0545 160.946 57.0545L164.518 53.7535V52.0548H156.024V53.7921H162.008L158.321 57.1896L159.248 58.7533Z"
                        fill="white"/>
                    <path
                        d="M170.46 64.5638V52.0548H168.8C168.511 53.406 167.314 54.3713 165.577 54.4099V55.7418H168.607V64.5638H170.46Z"
                        fill="white"/>
                    <path
                        d="M192.478 64.5638V50.8772H189.949L185.084 62.0157L180.258 50.8772H177.691V64.5638H179.563V53.908L184.196 64.5638H185.933L190.605 53.908V64.5638H192.478Z"
                        fill="white"/>
                    <path
                        d="M194.705 62.0736C194.705 63.5407 195.922 64.8534 197.813 64.8534C199.473 64.8534 200.4 63.9654 200.805 63.309C200.805 63.9847 200.863 64.3322 200.902 64.5638H202.678C202.639 64.3322 202.562 63.8496 202.562 63.0388V58.3865C202.562 56.5719 201.481 54.989 198.701 54.989C196.694 54.989 195.091 56.2244 194.898 58.0004L196.636 58.4058C196.752 57.3248 197.466 56.5333 198.74 56.5333C200.149 56.5333 200.748 57.2862 200.748 58.1935C200.748 58.5216 200.593 58.8112 200.033 58.8884L197.524 59.2552C195.922 59.4868 194.705 60.4134 194.705 62.0736ZM198.064 63.3284C197.138 63.3284 196.559 62.672 196.559 61.9771C196.559 61.147 197.138 60.703 197.91 60.5872L200.748 60.1625V60.5872C200.748 62.6141 199.551 63.3284 198.064 63.3284Z"
                        fill="white"/>
                    <path
                        d="M210.157 55.182C210.061 55.1627 209.81 55.1241 209.559 55.1241C208.613 55.1241 207.455 55.4716 206.779 56.9001V55.2785H205.003V64.5638H206.818V59.9115C206.818 57.8653 207.841 57.0545 209.366 57.0545C209.617 57.0545 209.887 57.0738 210.157 57.1124V55.182Z"
                        fill="white"/>
                    <path
                        d="M215.51 56.6684C217.093 56.6684 217.769 57.7302 218 58.6375L219.641 57.9425C219.255 56.5333 217.981 54.989 215.51 54.989C212.866 54.989 210.839 57.0159 210.839 59.9115C210.839 62.7299 212.827 64.8534 215.53 64.8534C217.943 64.8534 219.275 63.2897 219.719 61.9578L218.116 61.2628C217.904 61.9964 217.267 63.1739 215.53 63.1739C214.024 63.1739 212.692 62.035 212.692 59.9115C212.692 57.7495 214.043 56.6684 215.51 56.6684Z"
                        fill="white"/>
                    <path
                        d="M223.276 59.1008C223.314 57.7302 224.048 56.6491 225.438 56.6491C227.059 56.6491 227.6 57.6916 227.6 58.9849V64.5638H229.414V58.6761C229.414 56.6105 228.314 55.0083 226.094 55.0083C225.032 55.0083 223.951 55.3751 223.276 56.3596V50.5876H221.461V64.5638H223.276V59.1008Z"
                        fill="white"/>
                    <path
                        d="M125.303 75.8565C125.284 75.6635 125.264 75.4704 125.264 75.2967C125.264 73.8682 126.037 72.7292 127.677 72.7292C129.26 72.7292 130.033 73.791 130.033 74.9299C130.033 75.8951 129.492 76.7831 128.315 77.536L126.23 78.8873C124.106 80.2578 123.295 81.8215 123.257 83.8677H132.021V82.111H125.593C125.766 81.3389 126.403 80.8177 127.118 80.3544L129.589 78.7714C131.017 77.8641 131.944 76.5515 131.944 74.9106C131.944 72.8258 130.399 71.0691 127.716 71.0691C124.975 71.0691 123.392 72.9802 123.392 75.123C123.392 75.2774 123.392 75.4511 123.411 75.6249L125.303 75.8565Z"
                        fill="white"/>
                    <path
                        d="M135.25 77.6132C135.25 75.7021 135.598 74.3508 136.351 73.5593C136.833 73.0574 137.451 72.7485 138.339 72.7485C139.227 72.7485 139.845 73.0574 140.327 73.5593C141.08 74.3508 141.428 75.7021 141.428 77.6132C141.428 79.5243 141.08 80.8756 140.327 81.667C139.845 82.169 139.227 82.4778 138.339 82.4778C137.451 82.4778 136.833 82.169 136.351 81.667C135.598 80.8756 135.25 79.5243 135.25 77.6132ZM133.378 77.6132C133.378 79.3312 133.667 80.9142 134.498 82.1496C135.308 83.3658 136.563 84.1573 138.339 84.1573C140.115 84.1573 141.37 83.3658 142.181 82.1496C143.011 80.9142 143.3 79.3312 143.3 77.6132C143.3 75.8951 143.011 74.3122 142.181 73.0767C141.37 71.8606 140.115 71.0691 138.339 71.0691C136.563 71.0691 135.308 71.8606 134.498 73.0767C133.667 74.3122 133.378 75.8951 133.378 77.6132Z"
                        fill="white"/>
                    <path
                        d="M146.606 75.8565C146.587 75.6635 146.568 75.4704 146.568 75.2967C146.568 73.8682 147.34 72.7292 148.981 72.7292C150.564 72.7292 151.336 73.791 151.336 74.9299C151.336 75.8951 150.795 76.7831 149.618 77.536L147.533 78.8873C145.409 80.2578 144.599 81.8215 144.56 83.8677H153.324V82.111H146.896C147.069 81.3389 147.707 80.8177 148.421 80.3544L150.892 78.7714C152.32 77.8641 153.247 76.5515 153.247 74.9106C153.247 72.8258 151.702 71.0691 149.019 71.0691C146.278 71.0691 144.695 72.9802 144.695 75.123C144.695 75.2774 144.695 75.4511 144.714 75.6249L146.606 75.8565Z"
                        fill="white"/>
                    <path
                        d="M158.059 78.0572C158.387 77.9414 158.774 77.8641 159.16 77.8641C160.395 77.8641 161.708 78.5591 161.708 80.1999C161.708 81.4547 160.781 82.5164 159.063 82.5164C157.557 82.5164 156.361 81.474 156.283 79.8138L154.43 80.2385C154.546 82.2848 156.187 84.1766 159.082 84.1766C161.997 84.1766 163.619 82.2655 163.619 80.1613C163.619 77.5939 161.592 76.3584 159.758 76.3584L163.329 73.0574V71.3587H154.835V73.096H160.82L157.133 76.4935L158.059 78.0572Z"
                        fill="white"/>
                </svg>

            </Box>
        </Box>

    )
        ;
}

export default GrowUpLogo;
