import React from 'react';


const FORM_ID = "1JOTE7H5EYy83Xz9HX12nbaAdp6zOBPNiuKIwa86K4K4"
export const BASE_URL = "https://docs.google.com/forms/d/" + FORM_ID + "/formResponse"

const useFormsApi = () => {

    const doMultipart = async (url: string, body: FormData) => {
        return fetch(url, {
            method: 'POST',
            mode: 'no-cors',

            body: body,
        } as RequestInit);
    };

    const postFormData = (formData: FormData) => {
        return doMultipart(BASE_URL, formData);
    };

    return {postFormData};
};

export default useFormsApi;
