import React from "react";
import {Box, Stack} from "@mui/material";

function CircledContainer({
                              coverProps,
                              backgroundProps,
                              children
                          }: { coverProps?: any, backgroundProps?: any, children: JSX.Element }) {


    const styles = {
        beforeCircle: {
            paddingTop: "100%",
            content: "''",
            float: "left",
            width: "1px",
            marginLeft: "-1px",
            position: "relative",
        },
        afterCircle: {
            content: "''",
            display: "block",
            clear: "both"
        },
        opacityCircle: {
            opacity: "0.5",
            height: "100%",
            borderRadius: "50%",
            content: '""',
            display: "block",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            ...coverProps
        },
    };

    return (
        <Stack direction={"row"}>
            <Box sx={styles.beforeCircle}/>
            <Stack sx={{
                width: "100%",
                position: "relative",
                backgroundSize: "contain",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                ':before': styles.opacityCircle,
                ...backgroundProps
            }}>
                <Stack sx={{
                    alignItems: "center", position: "relative", justifyContent: "center", height: "100%"
                }}>
                    {children}
                </Stack>
            </Stack>
            <Box sx={styles.afterCircle}/>
        </Stack>
    )
        ;
}

export default CircledContainer;
