import React, {CSSProperties} from "react";
import {Box, Grid, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import CircledContainer from "../CircledContainer";
import theme from "../theme/theme";

function Venue() {

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const listStyle = {
        textAlign: "left",
        listStyleImage: 'url("/images/bullet.webp")',
        lineHeight: "1rem",
        listStylePosition: "outside",
        color: "#FFFFFF",
        marginTop: md ? "1rem" : "0.5rem",
        marginBottom: md ? "1rem" : "0.5rem",
    } as CSSProperties;

    return (
        <Box sx={{mt: {md: "6rem", xs: "3rem"}}}>
            <Grid container sx={{maxWidth: "90rem", width: "100%"}}>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        position: "relative",
                        height: "100%",
                        ':before': {
                            borderRadius: {md: "21.32rem 21.32rem 9rem 9rem", xs: "50vw 50vw 3.75rem 3.75rem"},
                            content: '""',
                            display: "block",
                            height: "100%",
                            position: "absolute",
                            width: "100%",
                            background: "conic-gradient(from 137.82deg at 50% 20.93%, #9E90EF -54.38deg, #8874E3 58.44deg, #9E90EF 305.62deg, #8874E3 418.44deg)",
                        }
                    }}>

                        <Stack sx={{
                            position: "relative",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            paddingLeft: {md: "5rem", xs: "1.5rem"},
                            paddingRight: {md: "5rem", xs: "1.5rem"},
                        }}>
                            <Typography sx={{textAlign: "center", marginTop: {md: "8rem", xs: "8rem"}}}>
                                Venue
                            </Typography>
                            <Typography typography={"h1"} sx={{textAlign: "center", mb: "3rem"}}>
                                Estonian Business School
                            </Typography>

                            <Grid container spacing={0}>
                                <Grid item md={6} xs={12}>
                                    <Stack sx={{alignItems: "start"}}>
                                        <ul style={listStyle}>
                                            <li>
                                                <Typography component={"span"}
                                                            sx={{
                                                                top: "-6px",
                                                                position: "relative",
                                                            }}>
                                                    Address<br/><br/>

                                                    A. Lauteri 3, Tallinn, Estonia<br/>
                                                    <Link href={"https://goo.gl/maps/eAb6rveYSBKQXfDG7"}
                                                          target={"_blank"}>Get directions</Link>
                                                </Typography>
                                            </li>

                                        </ul>
                                    </Stack>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Stack sx={{alignItems: "start", justifyContent: "center"}}>
                                        <ul style={listStyle}>
                                            <li>
                                                <Typography component={"span"}
                                                            sx={{top: "-6px", position: "relative"}}>
                                                    Anything else?<br/><br/>
                                                    Get in touch for special accommodation deals / private office room
                                                    usage / other questions <Link
                                                    href={"mailto:growup@kofounder.ee"}
                                                    target={"_blank"}>growup@kofounder.ee</Link>

                                                </Typography>
                                            </li>

                                        </ul>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>

                </Grid>
                <Grid item md={6} xs={12}>

                    <CircledContainer coverProps={{backgroundColor: "#0A003E"}}
                                      backgroundProps={{backgroundImage: "url(/images/ebs.webp)"}}>
                        <>
                            <Typography sx={{textAlign: "center"}}>
                                Organized by
                            </Typography>
                            <Stack direction={"row"}
                                   sx={{justifyContent: "center", alignItems: "top", mt: {md: "5rem", xs: "2rem"}}}>
                                <Box sx={{marginRight: "1.5rem"}}>
                                    <img src="/images/ebslogo.webp" width={md ? "100%" : "95px"} height={"auto"}/>
                                </Box>
                                <Box sx={{paddingTop: "0.7rem", marginLeft: "1.5rem"}}>
                                    <img src="/images/kofounderlogo.webp" width={md ? "100%" : "95px"} height={"auto"}/>
                                </Box>
                            </Stack>

                        </>
                    </CircledContainer>

                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default Venue;
